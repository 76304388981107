import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Modal, FormControl, Select,InputLabel, MenuItem } from '@mui/material';
import API from '../API/API';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { setUser } from '../../slices/userSlice';

interface EditUserInfoProps {
  open: boolean;
  onClose: () => void;
  user: {
    firstname: string;
    lastname: string;
    email: string;
    phonenumber: string;
    area: string;
    role: string;
    goal: string;
  };
}

const EditUserInfo: React.FC<EditUserInfoProps> = ({ open, onClose, user }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.user);

  const [firstname, setFirstname] = useState(user.firstname);
  const [lastname, setLastname] = useState(user.lastname);
  const [email, setEmail] = useState(user.email);
  const [phonenumber, setPhonenumber] = useState(user.phonenumber);
  const [newGoal, setNewGoal] = useState(user.goal);

  const handleSave = async () => {
    const updatedFields = {
      id: currentUser.id,
      firstname,
      lastname,
      email,
      phonenumber,
      newGoal
    };

    try {
      const response = await API.post('/updateUserInfo', updatedFields);
      if (response.status === 200) {
        dispatch(setUser({ ...currentUser, ...updatedFields }));
        onClose();
      }
    } catch (error) {
      console.error('Failed to update user info', error);
    }
  };
  const goalOptions = [
    { value: "Vaativat", label: "Vaativat tehtävät" },
    { value: "Perus", label: "Perustehtävät" },
    { value: "Muut", label: "Muut tehtävät" }
  ];

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="edit-user-info-title" aria-describedby="edit-user-info-description">
      <Box className="edit-user-info-modal" sx={{ p: 3, maxWidth: 400, margin: '0 auto', mt: '10%', bgcolor: 'background.paper', borderRadius: 2, boxShadow: 24 }}>
        <Typography id="edit-user-info-title" variant="h6" gutterBottom>
          Muokkaa tietoja
        </Typography>
        <TextField label="Etunimi" value={firstname} onChange={(e) => setFirstname(e.target.value)} fullWidth margin="normal" />
        <TextField label="Sukunimi" value={lastname} onChange={(e) => setLastname(e.target.value)} fullWidth margin="normal" />
        <TextField label="Sähköposti" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth margin="normal" />
        <TextField label="Puhelin" value={phonenumber} onChange={(e) => setPhonenumber(e.target.value)} fullWidth margin="normal" />
        <FormControl fullWidth margin="normal">
          <InputLabel>Tavoitetaso</InputLabel>
            <Select
              value={newGoal}
              onChange={(e) => setNewGoal(e.target.value)}
              fullWidth
            >
              <MenuItem value="" disabled hidden>Valitse tavoitetaso</MenuItem>
              {goalOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button variant="contained" color="success" onClick={handleSave}>
            Tallenna
          </Button>
          <Button color="error" onClick={onClose}>
            Peruuta
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditUserInfo;
