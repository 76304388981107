import React, { useEffect, useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';
import API from '../../API/API';
import { useScreenSize } from '../../ScreenSizeContext';

interface Location {
  fire_station: string;
  area: string;
}

const BikeLocations: React.FC = () => {
  const [bikeLocations, setBikeLocations] = useState<Location[]>([]);
  const { isMobile } = useScreenSize();

  const getBikeLocations = async () => {
    try {
      const response = await API.get('/getBikeLocationsForDashboardTab');
      setBikeLocations(response.data); // Store the data in the state
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getBikeLocations();
  }, []);

  // Group locations by area
  const groupedLocations = bikeLocations.reduce((acc: { [key: string]: Location[] }, location) => {
    if (!acc[location.area]) {
      acc[location.area] = [];
    }
    acc[location.area].push(location);
    return acc;
  }, {});

  return (
    <Box>
    <Paper elevation={3} sx={{padding: isMobile ? 1 : 2, margin: 1}}>
      {Object.keys(groupedLocations).map((area) => (
        <Box key={area} mb={4}>
          <Typography variant="h6" gutterBottom>
            {area}
          </Typography>
          <Paper elevation={3}>
            <Table>
              <TableBody>
                {groupedLocations[area].map((location) => (
                  <TableRow key={location.fire_station}>
                    <TableCell>{location.fire_station}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Box>
      ))}
      </Paper>
    </Box>
  );
};

export default BikeLocations;
