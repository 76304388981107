import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Booking {
  id: string;
  start: string;
  end: string;
  title: string;
  area: string;
  userId: string;
  userRole: string;
  firstname: string;
  lastname: string;
  phonenumber: string;
  fire_station: string;
  tester: string;
  approved: number;
}

interface Available {
  available_date_id: number;
  tester_firstname: string;
  tester_lastname: string;
  available_date: string;
  available_startTime: string;
  available_endTime: string;
  is_available: number;
  fire_station: string;
}

interface BookingState {
  bookings: Booking[];
  availableDates: Available[];
}

const initialState: BookingState = {
  bookings: [],
  availableDates: [],
};

const bookingSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    addBooking(state, action: PayloadAction<Booking>) {
      state.bookings.push(action.payload);
    },
    setBookings(state, action: PayloadAction<Booking[]>) {
      state.bookings = action.payload;
    },
    setAvailableDates(state, action: PayloadAction<Available[]>) {
      state.availableDates = action.payload;
    },
    deleteBooking(state, action: PayloadAction<string>) {
      state.bookings = state.bookings.filter(booking => booking.id !== action.payload);
    },
    updateBookingApprovedStatus(state, action: PayloadAction<{ id: string, approved: number }>) {
      const { id, approved } = action.payload;
      const booking = state.bookings.find(booking => booking.id === id);
      if (booking) {
        booking.approved = approved;
      }
    },
    removeAvailableDate(state, action: PayloadAction<number>) {
      state.availableDates = state.availableDates.filter(date => date.available_date_id !== action.payload);
    },
    clearBookingState(state) {
      return initialState;
    },
  },
});

export const {
  addBooking,
  setBookings,
  setAvailableDates,
  deleteBooking,
  updateBookingApprovedStatus,
  removeAvailableDate,
  clearBookingState
} = bookingSlice.actions;
export default bookingSlice.reducer;
