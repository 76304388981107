import React, { useState } from 'react';
import { Dialog, DialogTitle,Box, DialogContent, TextField, Button, Typography } from '@mui/material';
import API from '../API/API';

interface ChangePasswordModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  email: string | null;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ isOpen, onRequestClose, email }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const handleChangePassword = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await API.post('/change-password', { email, oldPassword, newPassword });
      setMessage(response.data.message);
      setIsError(false); // Set to false for success message
      setOldPassword('');
      setNewPassword('');
      setTimeout(() => {
        setMessage('');
        onRequestClose();
      }, 2000); // Close modal after 2 seconds
    } catch (error: any) {
      setMessage(error.response.data.message);
      setIsError(true); // Set to true for error message
      setTimeout(() => {
        setMessage('');
        setIsError(false);
      }, 3000);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onRequestClose} maxWidth="sm" fullWidth>
      <DialogTitle>Vaihda salasana</DialogTitle>
      <DialogContent>
        <form onSubmit={handleChangePassword}>
          <TextField
            label="Vanha salasana"
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label="Uusi salasana"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          {message && (
            <Typography color={isError ? 'error' : 'success'} sx={{ mt: 2 }}>
              {message}
            </Typography>
          )}
            <Box sx={{ mt: '10px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Button type="submit" variant="contained" color="success">Vaihda</Button>
              <Button onClick={onRequestClose} color="error">Peruuta</Button>
            </Box>


        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePasswordModal;
