import BookingsTable from './BookingsTable';
import CalendarTable from './CalendarTable';
import BookingFormModal from '../../UserView/Calendar/BookingFormModal';
import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, Typography, IconButton, Button, Snackbar, Alert } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useScreenSize } from '../../ScreenSizeContext';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import API from '../../API/API';
import { setBookings, setAvailableDates, removeAvailableDate, deleteBooking } from '../../../slices/bookingSlice';
import AbsenceModal from './AbsenceModalDashboard';

interface DashboardTabBookingsProps {
  role: string;
}

const DashboardTabBookings: React.FC<DashboardTabBookingsProps> = ({ role }) => {
  const [showCalendar, setShowCalendar] = useState(true);
  const dispatch = useDispatch();
  const [bookingModalOpen, setBookingModalOpen] = useState(false); // State to manage modal visibility
  const bookings = useSelector((state: RootState) => state.bookings.bookings || []);
  const availableDates = useSelector((state: RootState) => state.bookings.availableDates || []);
  const [absenceModalOpen, setAbsenceModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);          // Snackbar state for messages
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const { isMobile, isTablet, isDesktop } = useScreenSize();

  useEffect(() => {
    const fetchBookingsAndAvailableDates = async () => {
      try {
        const response = await API.get('/varaukset');
        const availableDatesResponse = await API.get('/available');
  
        const fetchedBookings = response.data.bookings.map((booking: any) => ({
          id: booking.id,
          start: booking.start,
          end: booking.end,
          title: booking.title,
          area: booking.area,
          userId: booking.userId,
          userRole: booking.userRole,
          firstname: booking.firstname,
          lastname: booking.lastname,
          phonenumber: booking.phonenumber,
          fire_station: booking.fire_station,
          tester: booking.tester,
          approved: booking.approved,
        }));
        const fetchedAvailableDates = availableDatesResponse.data.map((available: any) => ({
          available_date_id: available.id,
          tester_firstname: available.firstname,
          tester_lastname: available.lastname,
          available_date: available.date,
          available_startTime: available.start_time,
          available_endTime: available.end_time,
          is_available: available.is_available,
          fire_station: available.fire_station,
        }));
  
        dispatch(setBookings(fetchedBookings));
        dispatch(setAvailableDates(fetchedAvailableDates));
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    };
    fetchBookingsAndAvailableDates();
  }, [dispatch]);

  useEffect(() => {
    if (isMobile || isTablet) {
      setShowCalendar(false);
    } else if (isDesktop) {
      setShowCalendar(true);
    }
  }, [isMobile, isTablet, isDesktop]);

  const handleOpenModal = () => {
    setBookingModalOpen(true);
  };

  const handleCloseModal = () => {
    setBookingModalOpen(false);
  };

  const handleBookingSubmit = () => {
    setBookingModalOpen(false);
  };
  // Handler for marking absence
  const handleMarkAbsence = async (availableDateId: number, absenceReason: string) => {
    const absenceBody = { availableDateId, absenceReason };

    try {
      const result = await API.post('/mark-absence', absenceBody);
      result.data.deletedBookingIds.forEach((id: string) => {
        dispatch(deleteBooking(id));
      });
      dispatch(removeAvailableDate(availableDateId));
      setSnackbarMessage(result.data.message);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error: any) {
      setSnackbarMessage(error.data.message || 'Error marking absence');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }

    setAbsenceModalOpen(false);
  };

  // Handle Snackbar close
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Map the bookings array to match the expected structure of existingBookings
  const existingBookings = bookings.map(booking => ({
    start: booking.start,
    end: booking.end,
    fire_station: booking.fire_station || ''
  }));

  return (
    <Box>
      <Button 
      variant="contained" 
      color="success" 
      onClick={handleOpenModal}
      sx={{ marginBottom: 2}}
      >
        Luo uusi ajanvaraus
      </Button>
        <Button 
          variant="contained" 
          color="error" 
          onClick={() => setAbsenceModalOpen(true)}  // Open absence modal
          sx={{ marginBottom: 2, marginLeft: 2 }}
        >
          Lisää poissaolo
        </Button>
      <Grid container spacing={3} style={{ marginBottom: '16px' }}>
        <Grid item xs={12} md={6}>
          <BookingsTable bookings={bookings} role={role} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" gutterBottom>
                Kalenterinäkymä
              </Typography>

              <IconButton onClick={() => setShowCalendar(!showCalendar)}>
                {showCalendar ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Box>

            {showCalendar && <CalendarTable bookings={bookings} role={role} />}
          </Paper>
        </Grid>
      </Grid>

      <BookingFormModal
        isOpen={bookingModalOpen}
        onRequestClose={handleCloseModal}
        onSubmit={handleBookingSubmit}
        selectedStartTime="" 
        selectedEndTimeStr="" 
        role={role}
        availableDates={availableDates}
        existingBookings={existingBookings}
      />
      <AbsenceModal
        isOpen={absenceModalOpen}
        onClose={() => setAbsenceModalOpen(false)}
        onMarkAbsence={handleMarkAbsence}
        availableDates={availableDates}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DashboardTabBookings;
