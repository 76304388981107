// slices/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  role: string;
  area: string;
  phonenumber: string;
  ergometry_expirary_date: string;
  fitness_test_expirary_date: string;
  oulunmalli_expirary_date: string;
  mobility_test_expirary_date: string;
  goal: string;
  fire_station: string;
}

const initialState: UserState = {
  id: '',
  firstname: '',
  lastname: '',
  email: '',
  role: '',
  area: '',
  phonenumber: '',
  ergometry_expirary_date: '',
  fitness_test_expirary_date: '',
  oulunmalli_expirary_date: '',
  mobility_test_expirary_date: '',
  goal: '',
  fire_station: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserState>) {
      state.id = action.payload.id;
      state.firstname = action.payload.firstname;
      state.lastname = action.payload.lastname;
      state.email = action.payload.email;
      state.role = action.payload.role;
      state.area = action.payload.area;
      state.phonenumber = action.payload.phonenumber;
      state.ergometry_expirary_date = action.payload.ergometry_expirary_date;
      state.fitness_test_expirary_date = action.payload.fitness_test_expirary_date;
      state.oulunmalli_expirary_date = action.payload.oulunmalli_expirary_date;
      state.mobility_test_expirary_date = action.payload.mobility_test_expirary_date;
      state.goal = action.payload.goal;
      state.fire_station = action.payload.fire_station;
    },
    clearUserState(state) {
      state.id = '';
      state.firstname = '';
      state.lastname = '';
      state.email = '';
      state.role = '';
      state.area = '';
      state.phonenumber = '';
      state.ergometry_expirary_date = '';
      state.fitness_test_expirary_date = '';
      state.oulunmalli_expirary_date = '';
      state.mobility_test_expirary_date = '';
      state.goal = '';
      state.fire_station = '';
    },
  },
});

export const { setUser, clearUserState } = userSlice.actions;
export default userSlice.reducer;
