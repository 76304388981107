import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Select, MenuItem, Typography, FormControl, InputLabel, IconButton, Grid, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import API from '../API/API';
import { useScreenSize } from '../ScreenSizeContext';

interface RegisterProps {
  onClose: () => void;
  userRole: string;
}
interface Option {
  value: string;
  label: string;
  area?: string;
}

const Register: React.FC<RegisterProps> = ({ onClose, userRole }) => {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [area, setArea] = useState(''); // Default area
  const [role, setRole] = useState(''); // Default role
  const [ergometryExpiraryDate, setErgometryExpiraryDate] = useState('');
  const [fitnessTestExpiraryDate, setFitnessTestExpiraryDate] = useState('');
  const [oulunmalliExpiraryDate, setOulunmalliExpiraryDate] = useState('');
  const [mobilityTestExpiraryDate, setMobilityTestExpiraryDate] = useState('');
  const [ergometryIndex, setErgometryIndex] = useState('');
  const [fitnessTestIndex, setFitnessTestIndex] = useState('');
  const [goal, setGoal] = useState('');
  const [fireStation, setFireStation] = useState('');
  const [message, setMessage] = useState('');
  const { isMobile } = useScreenSize();

  const [roleOptions, setRoleOptions] = useState<Option[]>([]);
  const [areaOptions, setAreaOptions] = useState<Option[]>([]);
  const [fireStations, setFireStations] = useState<Option[]>([]);
  const [goalOptions, setGoalOptions] = useState<Option[]>([]);
  const [filteredFireStations, setFilteredFireStations] = useState<Option[]>([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await API.get('/getOptions');
        setRoleOptions(response.data.roles);
        setAreaOptions(response.data.areas);
        setFireStations(response.data.fireStations);
        setGoalOptions(response.data.goals);
      } catch (error) {
        console.error('Failed to fetch options:', error);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    if (area) {
      const filtered = fireStations.filter(station => station.area === area);
      setFilteredFireStations(filtered);
      setFireStation(''); // Reset fire station selection when area changes
    }
  }, [area, fireStations]);

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await API.post('/register', {
        firstname, lastname, phonenumber, email, area, role,
        ergometryExpiraryDate, fitnessTestExpiraryDate, ergometryIndex, oulunmalliExpiraryDate, mobilityTestExpiraryDate, fitnessTestIndex,
        goal, fireStation
      });
      setMessage('Rekisteröinti onnistui. Käyttäjälle on lähetetty sähköposti-ilmoitus');
      onClose();
    } catch (error: any) {
      setMessage(error.response.data.message);
    }
  };

  return (
    <Box className="register-container" sx={{ position: 'relative', padding: 2}}>
      {isMobile && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', top: 3, right: 3 }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <Typography variant="h5" gutterBottom textAlign="center" marginBottom='20px'>Lisää uusi käyttäjä</Typography>
      <form onSubmit={handleRegister}>
      <Box 
          sx={{
            maxHeight: '60vh', 
            overflowY: 'auto',
          }}
        >
          {isMobile ? (
            <>
            <Typography color="gray">Perustiedot (pakolliset)</Typography>
              <TextField
                label="Etunimi"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label="Sukunimi"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label="Puhelin"
                value={phonenumber}
                onChange={(e) => setPhonenumber(e.target.value)}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label="Sähköposti"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                fullWidth
                margin="normal"
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Alue</InputLabel>
                <Select
                  value={area}
                  onChange={(e) => setArea(e.target.value)}
                  required
                  fullWidth
                >
                  <MenuItem value="" disabled hidden>Valitse alue</MenuItem>
                  {areaOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Paloasema:</InputLabel>
                <Select
                  value={fireStation}
                  onChange={(e) => setFireStation(e.target.value)}
                  required
                  fullWidth
                >
                  <MenuItem value="" disabled hidden>Valitse paloasema</MenuItem>
                  {filteredFireStations.map(option => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Rooli</InputLabel>
                <Select
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  required
                  fullWidth
                >
                  <MenuItem value="" disabled hidden>Valitse Rooli</MenuItem>
                  {roleOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography color="gray" sx={{marginTop: 1}}>FireFit-tiedot (nämä voi jättää tyhjäksi)</Typography>
              <TextField
                label="Polkupyöräergometri Vanhenee"
                type="date"
                value={ergometryExpiraryDate}
                onChange={(e) => setErgometryExpiraryDate(e.target.value)}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Lihaskuntotesti Vanhenee"
                type="date"
                value={fitnessTestExpiraryDate}
                onChange={(e) => setFitnessTestExpiraryDate(e.target.value)}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                  label="Savusukellusta simuloiva rata vanhenee"
                  type="date"
                  value={oulunmalliExpiraryDate}
                  onChange={(e) => setOulunmalliExpiraryDate(e.target.value)}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="Liikkuvuustesti vanhenee"
                  type="date"
                  value={mobilityTestExpiraryDate}
                  onChange={(e) => setMobilityTestExpiraryDate(e.target.value)}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              <TextField
                label="Polkupyöräergometrin viimeisin indeksi"
                value={ergometryIndex}
                onChange={(e) => setErgometryIndex(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Lihaskuntotestin viimeisin indeksi"
                value={fitnessTestIndex}
                onChange={(e) => setFitnessTestIndex(e.target.value)}
                fullWidth
                margin="normal"
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Tavoitetaso</InputLabel>
                <Select
                  value={goal}
                  onChange={(e) => setGoal(e.target.value)}
                  fullWidth
                >
                  <MenuItem value="" disabled hidden>Valitse tavoitetaso</MenuItem>
                  {goalOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography color="gray">Perustiedot (pakolliset)</Typography>
                <TextField
                  label="Etunimi"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  required
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Sukunimi"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  required
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Puhelin"
                  value={phonenumber}
                  onChange={(e) => setPhonenumber(e.target.value)}
                  required
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Sähköposti"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  fullWidth
                  margin="normal"
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel>Alue</InputLabel>
                  <Select
                    value={area}
                    onChange={(e) => setArea(e.target.value)}
                    required
                    fullWidth
                  >
                    <MenuItem value="" disabled hidden>Valitse alue</MenuItem>
                    {areaOptions.map(option => (
                      <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                <InputLabel>Paloasema:</InputLabel>
                <Select
                  value={fireStation}
                  onChange={(e) => setFireStation(e.target.value)}
                  required
                  fullWidth
                >
                  <MenuItem value="" disabled hidden>Valitse paloasema</MenuItem>
                  {filteredFireStations.map(option => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Rooli</InputLabel>
                  <Select
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    required
                    fullWidth
                  >
                    <MenuItem value="" disabled hidden>Valitse Rooli</MenuItem>
                    {roleOptions.map(option => (
                      <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography color="gray">FireFit-tiedot (nämä voi jättää tyhjäksi)</Typography>
                <TextField
                  label="Polkupyöräergometri vanhenee"
                  type="date"
                  value={ergometryExpiraryDate}
                  onChange={(e) => setErgometryExpiraryDate(e.target.value)}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="Lihaskuntotesti vanhenee"
                  type="date"
                  value={fitnessTestExpiraryDate}
                  onChange={(e) => setFitnessTestExpiraryDate(e.target.value)}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="Savusukellusta simuloiva rata vanhenee"
                  type="date"
                  value={oulunmalliExpiraryDate}
                  onChange={(e) => setOulunmalliExpiraryDate(e.target.value)}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="Liikkuvuustesti vanhenee"
                  type="date"
                  value={mobilityTestExpiraryDate}
                  onChange={(e) => setMobilityTestExpiraryDate(e.target.value)}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                label="Polkupyöräergometrin viimeisin indeksi"
                value={ergometryIndex}
                onChange={(e) => setErgometryIndex(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Lihaskuntotestin viimeisin indeksi"
                value={fitnessTestIndex}
                onChange={(e) => setFitnessTestIndex(e.target.value)}
                fullWidth
                margin="normal"
              />
                <FormControl fullWidth margin="normal">
                <InputLabel>Tavoitetaso</InputLabel>
                <Select
                  value={goal}
                  onChange={(e) => setGoal(e.target.value)}
                  fullWidth
                >
                  <MenuItem value="" disabled hidden>Valitse tavoitetaso</MenuItem>
                  {goalOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              </Grid>
            </Grid>
          )}
        </Box>
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>Lisää uusi käyttäjä</Button>
      </form>
      {message && <Typography color="error" sx={{ mt: 2 }}>{message}</Typography>}
    </Box>
  );
};

export default Register;
