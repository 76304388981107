import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import API from '../API/API';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import ConfirmDialog from './DialogBox';

interface FeedbackModalProps {
  isOpen: boolean;
  onClose: () => void;
  isMobile?: boolean;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ isOpen, onClose, isMobile }) => {
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const user = useSelector((state: RootState) => state.user);
  const { firstname, lastname, phonenumber, email, role } = user;

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await API.post('/send-feedback', { 
        feedback,
        user: {
            role,
            firstname,
            lastname,
            phonenumber,
            email,
            isMobile
          },
      });
      alert('Palautetta lähetetty onnistuneesti.');
      onClose();
    } catch (error) {
      console.error('Palauteen lähettäminen epäonnistui:', error);
      alert('Palauteen lähettäminen epäonnistui.');
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmDialogConfirm = () => {
    setConfirmDialogOpen(false);
    handleSubmit();
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <Box 
          sx={{ 
            width: isMobile ? '90%' : 400, 
            padding: isMobile ? 2 : 4, 
            margin: 'auto', 
            mt: isMobile ? '20%' : '10%', 
            bgcolor: 'white', 
            borderRadius: 2 
          }}
        >
          <Typography variant="h6" gutterBottom sx={{ marginBottom: 2 }}>
            Anna palautetta
          </Typography>
          <Typography 
            variant="body1" 
            color="textSecondary" 
            margin="normal" 
            component="div" 
            sx={{
              border: '1px solid green',
              borderRadius: '5px',
              backgroundColor: '#e0f2f1',
              padding: '10px',
              lineHeight: '1.5',
              marginBottom: 3,
            }}
          >
            Kerro meille, miten voimme parantaa sovellusta tai jos sinulla on ehdotuksia uusia ominaisuuksia varten!
            <br />
            Palautteesi on meille arvokasta ja auttaa meitä kehittämään sovellusta paremmaksi.
            <br />
            Kiitos, että autat meitä tekemään sovelluksesta paremman kaikille käyttäjille.
          </Typography>
          <TextField
            label="Anna palautetta tai parannusehdotuksia"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={handleConfirm} color="success" variant="contained" disabled={loading}>
              Lähetä
            </Button>
            <Button onClick={onClose} color="error" variant="outlined">
              Peruuta
            </Button>
          </Box>
        </Box>
      </Modal>
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        onConfirm={handleConfirmDialogConfirm}
        title="Vahvista palaute"
        contentText="Oletko varma, että haluat lähettää tämän palautteen? Kiitos, että autat meitä parantamaan sovellusta."
        cancelText="Peruuta"
        confirmText="Vahvista"
        confirmButtonColor='success'
      />
    </>
  );
};

export default FeedbackModal;
