import CryptoJS from 'crypto-js';

const SECRET_KEY: string = process.env.REACT_APP_SECRET_KEY as string;

interface EncryptedStorage {
  getItem: (key: string) => Promise<string | null>;
  setItem: (key: string, value: string) => Promise<void>;
  removeItem: (key: string) => Promise<void>;
}

const encryptedLocalStorage: EncryptedStorage = {
  getItem: async (key: string): Promise<string | null> => {
    const encryptedData = localStorage.getItem(key);
    if (!encryptedData) {
      return null;
    }
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  },
  setItem: async (key: string, value: string): Promise<void> => {
    const encryptedData = CryptoJS.AES.encrypt(value, SECRET_KEY).toString();
    localStorage.setItem(key, encryptedData);
  },
  removeItem: async (key: string): Promise<void> => {
    localStorage.removeItem(key);
  },
};

export default encryptedLocalStorage;
