// BookingsTable.tsx
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TableContainer,
  SelectChangeEvent,
} from '@mui/material';
import { ExpandLess, ExpandMore, FilterList } from '@mui/icons-material';
import API from '../../API/API';
import FilterComponent from '../../FilterComponent';
import { useScreenSize } from '../../ScreenSizeContext';

interface Booking {
  id: string;
  start: string;
  end: string;
  title: string;
  area: string;
  userId: string;
  userRole?: string | null;
  firstname?: string;
  lastname?: string;
  phonenumber?: string;
  fire_station?: string;
  tester?: string;
  approved: number;
}

interface BookingsTableProps {
  bookings: Booking[];
  role: string;
}

const BookingsTable: React.FC<BookingsTableProps> = ({ bookings, role }) => {
  const [showBookings, setShowBookings] = useState(true);
  const [showFilters, setShowFilters] = useState(false); // State to manage filter visibility
  const [showFilterIcon, setShowFilterIcon] = useState(true);
  const [allBookings, setAllBookings] = useState<Booking[]>([]);
  const [filter, setFilter] = useState({ title: '', name: '', area: '', start: '', end: '' });
  const area = useSelector((state: RootState) => state.user.area);
  
  const { isMobile, isTablet, isDesktop } = useScreenSize();

  useEffect(() => {
    if (isMobile || isTablet) {
      setShowBookings(false);
    } else if (isDesktop) {
      setShowBookings(true);
    }
  }, [isMobile, isTablet, isDesktop]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (role === 'admin') {
          const bookingsResponse = await API.get('/getAllBookings');
          setAllBookings(bookingsResponse.data.bookings);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [role]);

  const displayedBookings = (role === 'admin' ? allBookings : bookings.filter(booking => booking.area === area))
    .filter(booking => 
      (filter.title === '' || booking.title.includes(filter.title)) &&
      (filter.name === '' || `${booking.firstname} ${booking.lastname}`.toLowerCase().includes(filter.name.toLowerCase())) &&
      (role === 'admin' ? (filter.area === '' || booking.area.includes(filter.area)) : true) &&
      (filter.start === '' || booking.start >= filter.start) &&
      (filter.end === '' || booking.end <= filter.end)
    );

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target;
    setFilter(prevFilter => ({
      ...prevFilter,
      [name]: value
    }));
  };

  const handleClearFilters = () => {
    setFilter({ title: '', name: '', area: '', start: '', end: '' });
  };

  const uniqueAreas = Array.from(new Set(displayedBookings.map(booking => booking.area)));
  const uniqueTitles = Array.from(new Set(displayedBookings.map(booking => booking.title)));

  function formatDate(dateString: string) {
    const [datePart, timePart] = dateString.split(' ');
    const [year, month, day] = datePart.split('-');
    const [hours, minutes] = timePart.split(':');
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  return (
    <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" gutterBottom>
          Tehdyt varaukset
        </Typography>
        <Box display="flex" alignItems="center">
            {showFilterIcon && (
                <IconButton onClick={() => setShowFilters(!showFilters)}>
                    <FilterList />
                </IconButton>
            )}

            <IconButton 
            onClick={() => {
                setShowBookings(!showBookings);
                if (showBookings) {
                setShowFilterIcon(false);
                setShowFilters(false);
                } else {
                    setShowFilterIcon(true);
                }
            }}
            >
            {showBookings ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
        </Box>
      </Box>
      {showFilters && (
        <FilterComponent
          filter={filter}
          fields={[
            { label: 'Testi', name: 'title', type: 'select', options: uniqueTitles },
            { label: 'Nimi', name: 'name', type: 'text' },
            { label: 'Aloitus', name: 'start', type: 'date' },
            { label: 'Lopetus', name: 'end', type: 'date' },
            ...(role === 'admin' ? [{ label: 'Alue', name: 'area', type: 'select', options: uniqueAreas }] : [])
          ]}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
        />
      )}
      {showBookings && (
        <TableContainer style={{ maxHeight: 600 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Testi</TableCell>
                <TableCell>Varaaja</TableCell>
                <TableCell>Puhelinnumero</TableCell>
                <TableCell>Aloitus</TableCell>
                <TableCell>Lopetus</TableCell>
                <TableCell>Alue</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedBookings.length > 0 ? (
                displayedBookings.map(booking => (
                  <TableRow key={booking.id}>
                    <TableCell>{booking.title}</TableCell>
                    <TableCell>
                      {booking.firstname} {booking.lastname}
                    </TableCell>
                    <TableCell>{booking.phonenumber}</TableCell>
                    <TableCell>{formatDate(booking.start)}</TableCell>
                    <TableCell>{formatDate(booking.end)}</TableCell>
                    <TableCell>{booking.area}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    Varauksia ei löytynyt
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};

export default BookingsTable;
