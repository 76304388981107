import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Paper, Typography } from '@mui/material';
import moment from 'moment';

interface TestExpiryTrendsProps {
  labels: string[];
  data: number[];
}

const TestExpiryTrends: React.FC<TestExpiryTrendsProps> = ({ labels, data }) => {
  const chartData = {
    labels,
    datasets: [
      {
        label: 'Expiring Tests',
        backgroundColor: '#FF6384',
        borderColor: '#FF6384',
        data,
      },
    ],
  };
  const now = moment().format('YYYY');

  return (
    <Paper elevation={3} style={{ padding: '16px' }}>
      <Typography variant="h6" gutterBottom>
        Vanhenevat testit {now}
      </Typography>
      <Bar data={chartData} />
          
    </Paper>
        
);
};

export default TestExpiryTrends;
