// TestersTable.tsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TableContainer,
  SelectChangeEvent,
} from '@mui/material';
import { ExpandLess, ExpandMore, FilterList, Clear } from '@mui/icons-material';
import FilterComponent from '../../FilterComponent'; // Ensure the path is correct
import { useScreenSize } from '../../ScreenSizeContext';

interface User {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  role: string;
  area: string;
  fire_station: string;
  phonenumber: string;
}

interface TestersTableProps {
  testers: User[];
}

const TestersTable: React.FC<TestersTableProps> = ({ testers }) => {
  const [showUsers, setShowUsers] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [showFilterIcon, setShowFilterIcon] = useState(true);
  const [filter, setFilter] = useState({ name: '' });

  const { isMobile, isTablet, isDesktop } = useScreenSize();

  useEffect(() => {
    if (isMobile || isTablet) {
      setShowUsers(false);
    } else if (isDesktop) {
      setShowUsers(true);
    }
  }, [isMobile, isTablet, isDesktop]);

  const displayedTesters = testers.filter(user =>
    (filter.name === '' || `${user.firstname} ${user.lastname}`.toLowerCase().includes(filter.name.toLowerCase()))
  );

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target;
    setFilter(prevFilter => ({
      ...prevFilter,
      [name]: value
    }));
  };

  const handleClearFilters = () => {
    setFilter({ name: '' });
  };

  const isFilterActive = Object.values(filter).some(value => value !== '');

  return (
    <Paper elevation={3} style={{ padding: '16px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" gutterBottom>
          Alueesi testaajat
        </Typography>
        <Box display="flex" alignItems="center">
          {showFilterIcon && (
            <IconButton onClick={() => setShowFilters(!showFilters)}>
              <FilterList />
            </IconButton>
          )}
          <IconButton 
            onClick={() => {
              setShowUsers(!showUsers);
              if (showUsers) {
                setShowFilterIcon(false);
                setShowFilters(false);
              } else {
                setShowFilterIcon(true);
              }
            }}
          >
            {showUsers ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
      </Box>
      {showFilters && (
        <FilterComponent
          filter={filter}
          fields={[
            { label: 'Nimi', name: 'name', type: 'text' }
          ]}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
        />
      )}
      {showUsers && (
        <TableContainer style={{ maxHeight: 300 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Nimi</TableCell>
                <TableCell>Sähköposti</TableCell>
                <TableCell>Puhelinnumero</TableCell>
                <TableCell>Alue</TableCell>
                <TableCell>Paloasema</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedTesters.length > 0 ? (
                displayedTesters.map(user => (
                  <TableRow key={user.id}>
                    <TableCell>
                      {user.firstname} {user.lastname}
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.phonenumber}</TableCell>
                    <TableCell>{user.area}</TableCell>
                    <TableCell>{user.fire_station}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    Ei testaajia
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};

export default TestersTable;
