import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Paper, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

interface BookingLineGraphProps {
  lineGraphData: {
    availability: { date: string; Morning: number; Afternoon: number; Evening: number }[];
    bookings: { date: string; Morning: number; Afternoon: number; Evening: number }[];
  };
}

const BookingLineGraph: React.FC<BookingLineGraphProps> = ({ lineGraphData }) => {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<'Morning' | 'Afternoon' | 'Evening'>('Morning');

  const allDates = Array.from(new Set([
    ...lineGraphData.availability.map(item => new Date(item.date).toLocaleDateString()),
    ...lineGraphData.bookings.map(item => new Date(item.date).toLocaleDateString())
  ])).sort();

  const timeSlots = ['Morning', 'Afternoon', 'Evening'] as const;
  type TimeSlot = typeof timeSlots[number];

  const timeSlotLabels: Record<TimeSlot, string> = {
    Morning: 'Aamu',
    Afternoon: 'Iltapäivä',
    Evening: 'Ilta'
  };

  const dateToTimeSlotHours: { [key: string]: { [key in TimeSlot]: { available: number; booked: number; } } } = {};

  allDates.forEach(date => {
    dateToTimeSlotHours[date] = { Morning: { available: 0, booked: 0 }, Afternoon: { available: 0, booked: 0 }, Evening: { available: 0, booked: 0 } };
  });

  lineGraphData.availability.forEach(item => {
    const date = new Date(item.date).toLocaleDateString();
    dateToTimeSlotHours[date].Morning.available = item.Morning;
    dateToTimeSlotHours[date].Afternoon.available = item.Afternoon;
    dateToTimeSlotHours[date].Evening.available = item.Evening;
  });

  lineGraphData.bookings.forEach(item => {
    const date = new Date(item.date).toLocaleDateString();
    dateToTimeSlotHours[date].Morning.booked = item.Morning;
    dateToTimeSlotHours[date].Afternoon.booked = item.Afternoon;
    dateToTimeSlotHours[date].Evening.booked = item.Evening;
  });

  const totalHours: { [key in TimeSlot]: { available: number; booked: number; percentage: number } } = {
    Morning: { available: 0, booked: 0, percentage: 0 },
    Afternoon: { available: 0, booked: 0, percentage: 0 },
    Evening: { available: 0, booked: 0, percentage: 0 }
  };

  allDates.forEach(date => {
    timeSlots.forEach(slot => {
      totalHours[slot].available += dateToTimeSlotHours[date][slot].available;
      totalHours[slot].booked += dateToTimeSlotHours[date][slot].booked;
    });
  });

  timeSlots.forEach(slot => {
    if (totalHours[slot].available > 0) {
      totalHours[slot].percentage = (totalHours[slot].booked / totalHours[slot].available) * 100;
    } else {
      totalHours[slot].percentage = 0;
    }
  });

  const generateData = (timeSlot: TimeSlot) => {
    return {
      labels: allDates,
      datasets: [
        {
          label: `Suunnitellut ajat (${timeSlotLabels[timeSlot]})`,
          data: allDates.map(date => dateToTimeSlotHours[date][timeSlot].available),
          fill: false,
          borderColor: 'rgba(75, 192, 192, 1)',
          tension: 0.1,
        },
        {
          label: `Varatut ajat (${timeSlotLabels[timeSlot]})`,
          data: allDates.map(date => dateToTimeSlotHours[date][timeSlot].booked),
          fill: false,
          borderColor: 'rgba(153, 102, 255, 0.5)',
          tension: 0.1,
        },
      ],
    };
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
      <Typography variant="h6" gutterBottom>
        Varausaste {totalHours[selectedTimeSlot].percentage.toFixed(2)}%
      </Typography>
      <FormControl fullWidth margin="normal">
        <InputLabel>Aikaväli</InputLabel>
        <Select
          value={selectedTimeSlot}
          onChange={(e) => setSelectedTimeSlot(e.target.value as TimeSlot)}
          label="Aikaväli"
        >
          {timeSlots.map((slot) => (
            <MenuItem key={slot} value={slot}>
              {timeSlotLabels[slot]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Line data={generateData(selectedTimeSlot)} options={options} />
    </Paper>
  );
};

export default BookingLineGraph;
