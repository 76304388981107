import React, { useState, useEffect } from 'react';
import { Grid, List, ListItem, ListItemText } from '@mui/material';
import API from '../../API/API';
import TestersTable from './TestersTable';
import RegularUserTable from './RegularUserTable';
import SupervisorsTable from './SupervisorsTable';
import AdminsTable from './AdminsTable';

interface User {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  role: string;
  area: string;
  phonenumber: string;
  ergometry_expirary_date: string;
  fitness_test_expirary_date: string;
  oulun_malli_expirary_date: string;
  mobility_test_expirary_date: string;
  goal: string;
  fire_station: string;
  userIndex: string;
}

interface DashboardTabUsersProps {
  testers: User[];
  users: User[];
  role: string;
}

const DashboardTabUsers: React.FC<DashboardTabUsersProps> = ({ testers, users, role }) => {
  const [supervisorsAndAdmins, setSupervisorsAndAdmins] = useState<User[]>([]);
  const [selectedTable, setSelectedTable] = useState<string>('users'); // Default to show TestersTable

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (role === 'Esihenkilö') {
          const supervisorsAndAdminsResponse = await API.get('/getAllAdminsAndSupervisors');
          setSupervisorsAndAdmins(supervisorsAndAdminsResponse.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [role]);

  let displayedTesters: User[] = [];
  let displayedUsers: User[] = [];
  let displayedSupervisors: User[] = [];
  let displayedAdmins: User[] = [];

  if (role === 'admin') {
    displayedTesters = users.filter(user => user.role === 'Testaaja');
    displayedUsers = users.filter(user => user.role === 'Testattava');
    displayedSupervisors = users.filter(user => user.role === 'Esihenkilö');
    displayedAdmins = users.filter(user => user.role === 'admin');
  } else {
    displayedTesters = testers;
    displayedUsers = users;
    displayedSupervisors = supervisorsAndAdmins.filter(user => user.role === 'Esihenkilö');
    displayedAdmins = supervisorsAndAdmins.filter(user => user.role === 'admin');
  }

  const renderSelectedTable = () => {
    switch (selectedTable) {
      case 'testers':
        return <TestersTable testers={displayedTesters} />;
      case 'users':
        return <RegularUserTable users={displayedUsers} />;
      case 'supervisors':
        return <SupervisorsTable supervisors={displayedSupervisors} />;
      case 'admins':
        return <AdminsTable admins={displayedAdmins} />;
      default:
        return null;
    }
  };

  return (
    <Grid container spacing={3} style={{ marginBottom: '16px' }}>
      {/* Left Section - 1/5th of the window */}
      <Grid item xs={12} md={2}>
        <List component="nav">
          <ListItem
            onClick={() => setSelectedTable('users')}
            sx={{
              bgcolor: selectedTable === 'users' ? 'info.main' : 'inherit',
              color: selectedTable === 'users' ? 'info.contrastText' : 'inherit',
              borderRadius: '10px',
              cursor: 'pointer', '&:hover': {
                bgcolor: 'info.light',
                color: 'info.contrastText'
              },
              marginBottom: '2px'
            }}
          >
            <ListItemText
              primary="Testattavat (oma alue)"
            />
          </ListItem>
          <ListItem
            onClick={() => setSelectedTable('testers')}
            sx={{
              bgcolor: selectedTable === 'testers' ? 'info.main' : 'inherit',
              color: selectedTable === 'testers' ? 'info.contrastText' : 'inherit',
              borderRadius: '10px',
              cursor: 'pointer', '&:hover': {
                bgcolor: 'info.light',
                color: 'info.contrastText'
              },
              marginBottom: '2px'
            }}
          >
            <ListItemText
              primary="Testaajat (oma alue)"
            />
          </ListItem>
          {(role === 'admin' || role === 'Esihenkilö') && (
            <>
              <ListItem
                onClick={() => setSelectedTable('supervisors')}
                sx={{
                  bgcolor: selectedTable === 'supervisors' ? 'info.main' : 'inherit',
                  color: selectedTable === 'supervisors' ? 'info.contrastText' : 'inherit',
                  borderRadius: '10px',
                  cursor: 'pointer', '&:hover': {
                    bgcolor: 'info.light',
                    color: 'info.contrastText'
                  },
                  marginBottom: '2px'
                }}
              >
                <ListItemText
                  primary="Esihenkilöt (kaikki alueet)"
                />
              </ListItem>
              <ListItem
                onClick={() => setSelectedTable('admins')}
                sx={{
                  bgcolor: selectedTable === 'admins' ? 'info.main' : 'inherit',
                  color: selectedTable === 'admins' ? 'info.contrastText' : 'inherit',
                  borderRadius: '10px',
                  cursor: 'pointer', '&:hover': {
                    bgcolor: 'info.light',
                    color: 'info.contrastText'
                  },
                  marginBottom: '2px'
                }}
              >
                <ListItemText
                  primary="Pääkäyttäjät (kaikki alueet)"
                />
              </ListItem>
            </>
          )}
        </List>
      </Grid>

      {/* Right Section - 4/5ths of the window */}
      <Grid item xs={12} md={10}>
        {renderSelectedTable()}
      </Grid>
    </Grid>
  );
};

export default DashboardTabUsers;
