import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { DateSelectArg, EventClickArg } from '@fullcalendar/core';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import ReservationDetailsModal from '../../ReservationDetailsModal';
import API from '../../API/API';
import { Box } from '@mui/material';
import fiLocale from '@fullcalendar/core/locales/fi';
import { useScreenSize } from '../../ScreenSizeContext';

moment.locale('fi');

interface Booking {
  id: string;
  start: string;
  end: string;
  title: string;
  area: string;
  userId: string;
  userRole?: string | null;
  firstname?: string;
  lastname?: string;
  phonenumber?: string;
  fire_station?: string;
  tester?: string;
  approved: number;
}

interface CalendarTableProps {
  bookings: Booking[];
  role: string;
}

const CalendarTable: React.FC<CalendarTableProps> = ({ role }) => {
  const bookings = useSelector((state: RootState) => state.bookings.bookings || [])
  const [events, setEvents] = useState<Booking[]>(bookings);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [reservationDetailsModalOpen, setReservationDetailsModalOpen] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState<Booking | null>(null);
  const area = useSelector((state: RootState) => state.user.area);
  const calendarRef = useRef<FullCalendar>(null); // Ref to access FullCalendar instance

  const { isMobile, isTablet } = useScreenSize();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (role === 'admin') {
          const bookingsResponse = await API.get('/getAllBookings');
          setEvents(bookingsResponse.data.bookings);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (role === 'admin') {
      fetchData();
    }
  }, [role]);

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    setSelectedDate(selectInfo.start);
    if (calendarRef.current) {
      calendarRef.current.getApi().changeView('dayGridDay', selectInfo.start);
    }
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    setSelectedReservation(clickInfo.event.extendedProps as Booking);
    setReservationDetailsModalOpen(true);
  };

  return (
    <Box 
      sx={{ 
        height: isMobile ? '500px' : isTablet ? '500px' : '600px', 
        overflow: 'auto', 
        padding: 2,
        '& .fc-toolbar': {
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        },
        '& .fc-toolbar-title': {
          fontSize: isMobile ? '1rem' : isTablet ? '1.1rem' : '1.2rem',
        },
        '& .fc-button': {
          padding: isMobile ? '0.3rem 0.6rem' : isTablet ? '0.4rem 0.8rem' : '0.5rem 1rem',
          fontSize: isMobile ? '0.75rem' : isTablet ? '0.8rem' : '0.875rem',
          margin: isMobile ? '0.2rem' : isTablet ? '0.4rem 0.8rem' : '0.5rem 1rem',
          backgroundColor: '#0288d1',
          borderColor: '#007bff',
          color: 'white',
          borderRadius: '10px',
          marginRight: '8px',
        },
        '& .fc-button:hover': {
          backgroundColor: '#0056b3',
          borderColor: '#0056b3',
          color: 'white',
        },
        '& .fc-button.fc-button-active': {
          backgroundColor: '#004085',
          borderColor: '#004085',
          color: 'white',
        },
        '& .fc-button-group': {
          display: 'flex',
          alignItems: 'center',
        },
        '& .fc .fc-toolbar-chunk': {
          display: 'flex',
          alignItems: 'center',
        }
      }} 
      className="calendar-container-dashboard"
    >
      <FullCalendar
        ref={calendarRef} // Use ref to access FullCalendar instance
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        events={events.map(event => ({
          ...event,
          start: moment(event.start).toDate(),
          end: moment(event.end).toDate(),
          extendedProps: event
        }))}
        dateClick={(info) => setSelectedDate(info.date)}
        eventClick={handleEventClick}
        selectable
        select={handleDateSelect}
        locale={fiLocale}
        slotMinTime="08:00:00"
        slotMaxTime="23:59:59"
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }}
        displayEventTime
        height="100%"
        dayMaxEvents={2}
      />
      {selectedReservation && (
        <ReservationDetailsModal
          isOpen={reservationDetailsModalOpen}
          onClose={() => setReservationDetailsModalOpen(false)}
          reservation={selectedReservation}
        />
      )}
    </Box>
  );
};

export default CalendarTable;
