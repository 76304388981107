import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Box, Typography, Button, CardContent, CardActions, Avatar, Modal, CircularProgress, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import EditUserInfo from './EditUserInfo';
import './UserInfo.css';
import isWithinNextYear from '../utils/isWithinRange';
import API from '../API/API';
import { useScreenSize } from '../ScreenSizeContext';

interface UserInfoProps {
  open: boolean;
  onClose: () => void;
  onOpenChangePassword: () => void;
}

const UserInfo: React.FC<UserInfoProps> = ({ open, onClose, onOpenChangePassword }) => {
  const { firstname, lastname, email, phonenumber, area, fire_station, role, id, goal } = useSelector((state: RootState) => state.user);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [ergometryExpiraryDate, setErgometryExpiraryDate] = useState<string | null>(null);
  const [fitnessTestExpiraryDate, setFitnessTestExpiraryDate] = useState<string | null>(null);
  const [oulunMalliExpiraryDate, setOulunMalliExpiraryDate] = useState<string | null>(null);
  const [mobilityTestExpiraryDate, setMobilityTestExpiraryDate] = useState<string | null>(null);
  const [numericalErgoIndex, setNumericalErgoIndex] = useState<string | null>(null);
  const [numericalFtIndex, setNumericalFtIndex] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { isMobile } = useScreenSize();

  useEffect(() => {
    if (open) {
      fetchValidTestDatesAndIndex();
    }
  }, [open]);

  const fetchValidTestDatesAndIndex = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await API.get(`/getValidTestDatesAndIndex/${id}`);
      const { 
        ergometry_expirary_date, 
        fitness_test_expirary_date, 
        oulun_malli_expirary_date, 
        mobility_test_expirary_date, 
        numerical_ergo_index_encrypted,
        numerical_ft_index_encrypted
      } = response.data;

      setErgometryExpiraryDate(ergometry_expirary_date);
      setFitnessTestExpiraryDate(fitness_test_expirary_date);
      setOulunMalliExpiraryDate(oulun_malli_expirary_date);
      setMobilityTestExpiraryDate(mobility_test_expirary_date);

      setNumericalErgoIndex(numerical_ergo_index_encrypted !== null ? numerical_ergo_index_encrypted : null);
      setNumericalFtIndex(numerical_ft_index_encrypted !== null ? numerical_ft_index_encrypted : null);

      setLoading(false);
    } catch (error) {
      setError('Failed to fetch test dates and index');
      setLoading(false);
    }
  };

  const safeIsWithinNextYear = (date: string | null, goal: string) => {
    if (date === null) {
      return { isValid: false, expirationDate: '' };
    }
    return isWithinNextYear(date, goal);
  };

  const ergometryExpiry = safeIsWithinNextYear(ergometryExpiraryDate, goal);
  const fitnessTestExpiry = safeIsWithinNextYear(fitnessTestExpiraryDate, goal);
  const oulunMalliExpiry = safeIsWithinNextYear(oulunMalliExpiraryDate, goal);
  const mobilityTestExpiry = safeIsWithinNextYear(mobilityTestExpiraryDate, goal);

  const openEditModal = () => setIsEditModalOpen(true);
  const closeEditModal = () => setIsEditModalOpen(false);

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="user-info-title" aria-describedby="user-info-description" sx={{ overflow: 'auto'}}>
      <Box className="user-info-modal" sx={{ p: 3, maxWidth: 400, margin: 'auto', mt: '5%', bgcolor: 'background.paper', borderRadius: 2, boxShadow: 24, position: 'relative'}}>
        {isMobile && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
        )}
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <Avatar sx={{ width: 100, height: 100 }}>{firstname.charAt(0)}{lastname.charAt(0)}</Avatar>
              </Box>
              <Typography id="user-info-title" variant="h5" component="div" gutterBottom>
                {firstname} {lastname}
              </Typography>
              <Typography variant="body2" color="textSecondary" id="user-info-description">
                Sähköposti: {email}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Puhelin: {phonenumber}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Alue: {area}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Paloasema: {fire_station}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Rooli: {role}
              </Typography>
              <br></br>
              <Typography variant="body2" color="textSecondary">
                Polkupyöräergometri: 
                {ergometryExpiry.isValid ? (
                  <CheckCircleIcon sx={{ color: 'green', ml: 1, verticalAlign: 'middle' }} />
                ) : (
                  <CancelIcon sx={{ color: 'red', ml: 1, verticalAlign: 'middle' }} />
                )}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                <i>{ergometryExpiry.isValid ? `Vanhenee: ${ergometryExpiry.expirationDate}` : `Vanhentunut: ${ergometryExpiry.expirationDate}`}</i>
              </Typography>
              <br></br>
              <Typography variant="body2" color="textSecondary">
                Lihaskuntotesti: 
                {fitnessTestExpiry.isValid ? (
                  <CheckCircleIcon sx={{ color: 'green', ml: 1, verticalAlign: 'middle' }} />
                ) : (
                  <CancelIcon sx={{ color: 'red', ml: 1, verticalAlign: 'middle' }} />
                )}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                <i>{fitnessTestExpiry.isValid ? `Vanhenee: ${fitnessTestExpiry.expirationDate}` : `Vanhentunut: ${fitnessTestExpiry.expirationDate}`}</i>
              </Typography>
              <br></br>
              <Typography variant="body2" color="textSecondary">
                Savusukellusta simuloiva rata: 
                {oulunMalliExpiry.isValid ? (
                  <CheckCircleIcon sx={{ color: 'green', ml: 1, verticalAlign: 'middle' }} />
                ) : (
                  <CancelIcon sx={{ color: 'red', ml: 1, verticalAlign: 'middle' }} />
                )}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                <i>{oulunMalliExpiry.isValid ? `Vanhenee: ${oulunMalliExpiry.expirationDate}` : `Vanhentunut: ${oulunMalliExpiry.expirationDate}`}</i>
              </Typography>
              <br></br>
              <Typography variant="body2" color="textSecondary">
                Liikkuvuus-testi: 
                {mobilityTestExpiry.isValid ? (
                  <CheckCircleIcon sx={{ color: 'green', ml: 1, verticalAlign: 'middle' }} />
                ) : (
                  <CancelIcon sx={{ color: 'red', ml: 1, verticalAlign: 'middle' }} />
                )}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                <i>{mobilityTestExpiry.isValid ? `Vanhenee: ${mobilityTestExpiry.expirationDate}` : `Vanhentunut: ${mobilityTestExpiry.expirationDate}`}</i>
              </Typography>
              <br></br>
              <Typography variant='body2' color="textSecondary">Ergometrin indeksi: {numericalErgoIndex}</Typography>
              <Typography variant='body2' color="textSecondary">Lihaskuntotestin indeksi: {numericalFtIndex}</Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="success"
                startIcon={<EditIcon />}
                onClick={openEditModal}
                fullWidth
              >
                Muokkaa tietoja
              </Button>
            </CardActions>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                onClick={onOpenChangePassword}
                fullWidth
              >
                Vaihda salasana
              </Button>
            </CardActions>
            <EditUserInfo open={isEditModalOpen} onClose={closeEditModal} user={{ firstname, lastname, email, phonenumber, area, role, goal }} />
          </>
        )}
      </Box>
    </Modal>
  );
};

export default UserInfo;
