// Homepage.tsx
import React from 'react';
import UserView from '../UserView/UserViewDashboard';
import Layout from './Layout';

const Homepage: React.FC = () => {
  return (
    <Layout>
      <UserView />
    </Layout>
  );
};

export default Homepage;
