import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, IconButton, FormControl, Select, MenuItem, TextField, Snackbar, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { deleteBooking, updateBookingApprovedStatus } from '../slices/bookingSlice';
import API from './API/API';
import ConfirmDialog from './utils/DialogBox';
import { getStatus, getStatusStyles } from './utils/statusConverterFunctions';
import { useScreenSize } from './ScreenSizeContext';

interface ReservationDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  reservation: {
    id: string;
    title: string;
    start: string;
    end: string;
    firstname?: string | null;
    lastname?: string | null;
    phonenumber?: string | null;
    userId: string | null;
    fire_station?: string;
    tester?: string;
    approved?: number;
  };
}

const ReservationDetailsModal: React.FC<ReservationDetailsModalProps> = ({
  isOpen,
  onClose,
  reservation,
}) => {
  const { id, title, start, end, firstname, lastname, phonenumber, userId, fire_station, tester, approved } = reservation;
  const role = useSelector((state: RootState) => state.user.role);
  const currentUserId = useSelector((state: RootState) => state.user.id);
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmApprovedOpen, setConfirmApprovedOpen] = useState(false);
  const [confirmFailedOpen, setConfirmFailedOpen] = useState(false);
  const [rating, setRating] = useState('');
  const [ratingError, setRatingError] = useState('');
  const { isMobile } = useScreenSize();
  const [deleteCause, setDeleteCause] = useState<string>(''); // State to store selected cause string
  const [availableDeleteCauses, setAvailableDeleteCauses] = useState<Array<{ id: number; cause: string }>>([]); // Array to store causes
  const [indexValue, setIndexValue] = useState<string>('');
  const [snackbar, setSnackbar] = useState({open: false, message: '', severity: 'success'});

  const showSnackbar = (message: any, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  // Fetching delete causes from the API
  const fetchDeleteCauses = async () => {
    try {
      const response = await API.get('/getDeleteCauses');
      if (response.data) {
        setAvailableDeleteCauses(response.data);
      } 
    } catch(error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDeleteCauses();
  }, []);

  // Function to handle booking deletion
  const handleDelete = async () => {
    try {
      await API.delete(`/varaukset/${id}?role=${role}`, {
        data: { deleteCause },
      });
  
      dispatch(deleteBooking(id));
      setSnackbar({open: true, message: 'Varaus poistettu onnistuneesti.', severity: 'success'});
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error: any) {
      setSnackbar({open: true, message: error.response?.data?.message || 'Poisto epäonnistui', severity: 'error'});
    }
    setConfirmDeleteOpen(false);
  };

  const handleDeleteClick = () => {
    setConfirmDeleteOpen(true);
  };

  const handleCancelDelete = () => {
    setConfirmDeleteOpen(false);
  };

  const handleMarkApproved = async () => {

    try {
      const response = await API.post('/setExpiringDate', { userId, reservationId: reservation.id, title, end, rating, approved: 1, indexValue });
      setSnackbar({open: true, message: response?.data?.message, severity: 'success' });
      dispatch(updateBookingApprovedStatus({ id: reservation.id, approved: 1 }));
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error: any) {
      setSnackbar({open: true, message: error.response?.data?.message, severity: 'error'});
    }
    setConfirmApprovedOpen(false);
  };

  const handleMarkApprovedClick = () => {
    setConfirmApprovedOpen(true);
  };

  const handleCancelMarkApproved = () => {
    setConfirmApprovedOpen(false);
  };

  const handleMarkFailed = async () => {
    try {
      const response = await API.post('/setExpiringDate', { userId, reservationId: reservation.id, title, end, rating: 'Perus', approved: 2, indexValue: '0' });
      setSnackbar({open: true, message: response?.data?.message, severity: 'success' });
      dispatch(updateBookingApprovedStatus({ id: reservation.id, approved: 2 }));
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error: any) {
      setSnackbar({open: true, message: error.response?.data?.message, severity: 'error'});
    }
    setConfirmFailedOpen(false);
  };

  const handleMarkFailedClick = () => {
    setConfirmFailedOpen(true);
  };

  const handleCancelMarkFailed = () => {
    setConfirmFailedOpen(false);
  };

  function formatDate(dateString: string) {
    const [datePart, timePart] = dateString.split(' ');
    const [year, month, day] = datePart.split('-');
    const [hours, minutes] = timePart.split(':');
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  const isDeleteDisabled = () => {
    const startTime = new Date(start).getTime();
    const currentTime = Date.now();
    const twentyFourHoursInMs = 24 * 60 * 60 * 1000;
    return startTime - currentTime <= twentyFourHoursInMs;
  };

  const modalStyles = {
    p: 4,
    backgroundColor: 'white',
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    width: isMobile ? '80%' : '400px', // Adjust width based on screen size
    margin: 'auto', // Center the modal
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)', // Center the box within the modal
    position: 'absolute',
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <Box sx={modalStyles}>
          {isMobile && (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{ position: 'absolute', top: 8, right: 8 }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <Typography variant="h6" component="h2">
            Varauksen tiedot
          </Typography>
          <Box className="reservation-detail">
            <Typography variant="body1" component="p">
              <strong>Otsikko:</strong>
            </Typography>
            <Typography variant="body1" component="p">
              {title}
            </Typography>
          </Box>
          <Box className="reservation-detail">
            <Typography variant="body1" component="p">
              <strong>Aloitusaika:</strong>
            </Typography>
            <Typography variant="body1" component="p">
              {formatDate(start)}
            </Typography>
          </Box>
          <Box className="reservation-detail">
            <Typography variant="body1" component="p">
              <strong>Lopetusaika:</strong>
            </Typography>
            <Typography variant="body1" component="p">
              {formatDate(end)}
            </Typography>
          </Box>
          {firstname && lastname && (
            <>
              <Box className="reservation-detail">
                <Typography variant="body1" component="p">
                  <strong>Varauksen tekijä:</strong>
                </Typography>
                <Typography variant="body1" component="p">
                  {firstname} {lastname}
                </Typography>
              </Box>
              <Box className="reservation-detail">
                <Typography variant="body1" component="p">
                  <strong>Puhelinnumero:</strong>
                </Typography>
                <Typography variant="body1" component="p">
                  {phonenumber}
                </Typography>
              </Box>
              <Box className="reservation-detail">
                <Typography variant="body1" component="p">
                  <strong>Paloasema:</strong>
                </Typography>
                <Typography variant="body1" component="p">
                  {fire_station}
                </Typography>
              </Box>
              <Box className="reservation-detail">
                <Typography variant="body1" component="p">
                  <strong>Testaaja:</strong>
                </Typography>
                <Typography variant="body1" component="p">
                  {tester}
                </Typography>
              </Box>
              <Box className="reservation-detail">
                <Typography variant="body1" component="p">
                  <strong>Status:</strong>
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{
                    color: 'white',
                    backgroundColor: getStatusStyles(approved).backgroundColor,
                    borderRadius: '4px',
                    padding: '4px 6px',
                    display: 'inline-block',
                  }}
                >
                  {getStatus(approved)}
                </Typography>
              </Box>
            </>
          )}
          <Box className="modal-actions" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mt: 2 }}>
            {(role === 'Testaaja' || role === 'Esihenkilö' || role === 'admin') && (
              <>
                {approved === 0 && (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button variant="contained" color="success" onClick={handleMarkApprovedClick} fullWidth>Merkkaa hyväksytyksi</Button>
                    <Button variant="contained" color="error" onClick={handleMarkFailedClick} fullWidth>Merkkaa hylätyksi</Button>
                  </Box>
                )}
                {approved === 0 && (
                  <Button variant="outlined" color="error" onClick={handleDeleteClick} fullWidth>Poista</Button>
                )}
              </>
            )}
            {(role === 'Testattava' && currentUserId === userId && approved === 0) && (
              <>
                {!isDeleteDisabled() ? (
                  <Button variant="outlined" color="error" onClick={handleDeleteClick} fullWidth>Poista</Button>
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    Varausten poisto lukitaan 24h ennen testin alkua. Jos varauksesi pitää poistaa, ole yhteydessä alueesi esihenkilöön tai FireFit-testaajaan.
                  </Typography>
                )}
              </>
            )}
          </Box>
          {message && <Typography color="success" sx={{ mt: 2 }}>{message}</Typography>}
        </Box>
      </Modal>
      <ConfirmDialog
        open={confirmDeleteOpen}
        onClose={handleCancelDelete}
        onConfirm={handleDelete}
        title="Vahvista poistaminen"
        contentText="Oletko varma, että haluat poistaa tämän varauksen? Tämä toiminto on peruuttamaton."
        cancelText="Peruuta"
        confirmText="Poista"
        showCauseField={true}
        cause={deleteCause}
        setCause={setDeleteCause}
        availableDeleteCauses={availableDeleteCauses}
      />
      <ConfirmDialog
        open={confirmApprovedOpen && title === 'Savusukellusta simuloiva rata'}
        onClose={handleCancelMarkApproved}
        onConfirm={handleMarkApproved}
        title="Vahvista hyväksyntä"
        contentText="Oletko varma, että haluat hyväksyä tämän testin?"
        cancelText="Peruuta"
        confirmText="Hyväksy"
        confirmButtonColor='success'
      />
<Modal
  open={confirmApprovedOpen && title !== 'Savusukellusta simuloiva rata'}
  onClose={handleCancelMarkApproved}
  aria-labelledby="modal-title"
  aria-describedby="modal-description"
>
  <Box sx={modalStyles}>
    {isMobile && (
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{ position: 'absolute', top: 8, right: 8 }}
      >
        <CloseIcon />
      </IconButton>
    )}
    <Typography id="modal-title" variant="h6">
      Hyväksynnän vahvistaminen
    </Typography>
    <Typography id="modal-description" variant="body1" sx={{ mt: 2 }}>
      Anna testin FireFit-indeksi:
    </Typography>
    <Typography variant="caption" color="gray" sx={{fontStyle: 'italic'}}>
      *Indeksi on terveystietoa, joten se salataan ja näytetään ainoastaan testin tekijälle. Indeksiä käytetään taustaprosessissa laskemaan henkilön kelpoisuusluokitus.
    </Typography>
    <TextField 
      fullWidth 
      value={indexValue} 
      onChange={(e) => setIndexValue(e.target.value)} 
      sx={{ mt: 1 }}
    />
    {ratingError && (
      <Typography color="error" variant="body2" sx={{ mt: 1 }}>
        {ratingError}
      </Typography>
    )}
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
      <Button onClick={handleMarkApproved} variant="contained" color="success">
        Valmis
      </Button>
      <Button onClick={handleCancelMarkApproved} color="error">
        Peruuta
      </Button>
    </Box>
  </Box>
</Modal>
      <ConfirmDialog
        open={confirmFailedOpen}
        onClose={handleCancelMarkFailed}
        onConfirm={handleMarkFailed}
        title="Vahvista hylkäys"
        contentText="Oletko varma, että haluat hylätä tämän testin?"
        cancelText="Peruuta"
        confirmText="Hylkää"
        confirmButtonColor='error'
      />
      <Snackbar
      open={snackbar.open}
      autoHideDuration={6000}
      onClose={() => setSnackbar({ ...snackbar, open: false })}
      anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
    >
      <Alert
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        /* severity={snackbar.severity} */
        sx={{ width: '100%' }}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
    </>
  );
};

export default ReservationDetailsModal;
