// ErrorReportModal.tsx
import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import API from '../API/API';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import ConfirmDialog from './DialogBox';

interface ErrorReportModalProps {
  isOpen: boolean;
  onClose: () => void;
  isMobile?: boolean;
}

const ErrorReportModal: React.FC<ErrorReportModalProps> = ({ isOpen, onClose, isMobile }) => {
  const [errorDescription, setErrorDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const user = useSelector((state: RootState) => state.user);
  const { firstname, lastname, phonenumber, email, role } = user;

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await API.post('/send-error-report', { 
        description: errorDescription,
        user: {
            role,
            firstname,
            lastname,
            phonenumber,
            email,
            isMobile
          },
      });
      alert('Virheilmoitus lähetetty onnistuneesti.');
      onClose();
    } catch (error) {
      console.error('Virheilmoituksen lähettäminen epäonnistui:', error);
      alert('Virheilmoituksen lähettäminen epäonnistui.');
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmDialogConfirm = () => {
    setConfirmDialogOpen(false);
    handleSubmit();
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <Box 
          sx={{ 
            width: isMobile ? '90%' : 400, 
            padding: isMobile ? 2 : 4, 
            margin: 'auto', 
            mt: isMobile ? '20%' : '10%', 
            bgcolor: 'white', 
            borderRadius: 2 
          }}
        >
          <Typography variant="h6" gutterBottom sx={{ marginBottom: 2 }}>
            Luo virheilmoitus
          </Typography>
          <Typography 
            variant="body1" 
            color="textSecondary" 
            margin="normal" 
            component="div" 
            sx={{
              border: '1px solid red',
              borderRadius: '5px',
              backgroundColor: '#ffe5e5',
              padding: '10px',
              lineHeight: '1.5',
              marginBottom: 3,
            }}
          >
            Kuvaile kohtaamasi virhe tai ongelma mahdollisimman tarkasti!
            <br />
            Tärkeitä tietoja ovat mitä teit ja missä sovelluksen osassa, kun virhe tapahtui.
            <br />
            Yritä kertoa tapahtumien kulku selostavasti ja mahdollisimman yksityiskohtaisesti virheen tapahtumiseen saakka. 
            <br />
            <strong>Mainitse myös kellonaika mahdollisimman tarkasti, kun virhe tapahtui.</strong>
            <br />
            Jos olet pääkäyttäjä tai esihenkilö, muista mainita missä hallintapaneelin osassa virhe tapahtui.
            <br />
          </Typography>
          <TextField
            label="Kuvaile virhe tai ongelma"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            value={errorDescription}
            onChange={(e) => setErrorDescription(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={handleConfirm} color="success" variant="contained" disabled={loading}>
              Lähetä
            </Button>
            <Button onClick={onClose} color="error" variant="outlined">
              Peruuta
            </Button>
          </Box>
        </Box>
      </Modal>
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        onConfirm={handleConfirmDialogConfirm}
        title="Vahvista virheilmoitus"
        contentText="Huomioithan, että tämän virheilmoituksen kautta ei lähetetä varausaikojen puutteeseen tai muihin käyttäjäkohtaisiin ongelmiin liittyviä virheitä tai puutteita. Edellä mainituissa ongelmissa ole yhteydessä alueesi esihenkilöön."
        cancelText="Peruuta"
        confirmText="Vahvista"
        confirmButtonColor='success'
      />
    </>
  );
};

export default ErrorReportModal;
