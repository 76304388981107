import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface ScreenSizeContextProps {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

interface ScreenSizeProviderProps {
  children: ReactNode;
}

const ScreenSizeContext = createContext<ScreenSizeContextProps | undefined>(undefined);

export const useScreenSize = () => {
  const context = useContext(ScreenSizeContext);
  if (!context) {
    throw new Error('useScreenSize must be used within a ScreenSizeProvider');
  }
  return context;
};

export const ScreenSizeProvider: React.FC<ScreenSizeProviderProps> = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  const updateScreenSize = () => {
    const width = window.innerWidth;
    setIsMobile(width < 750);
    setIsTablet(width >= 750 && width < 1200);
    setIsDesktop(width >= 1200);
  };

  useEffect(() => {
    updateScreenSize();
    window.addEventListener('resize', updateScreenSize);
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return (
    <ScreenSizeContext.Provider value={{ isMobile, isTablet, isDesktop }}>
      {children}
    </ScreenSizeContext.Provider>
  );
};
