import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import {
  Box,
  Button,
  TextField,
  FormControlLabel,
  Typography,
  List,
  ListItem,
  Paper,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Card,
  CardContent,
  IconButton,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText as MuiListItemText,
  SelectChangeEvent,
} from '@mui/material';
import { blue, red } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import API from '../../API/API';
import ConfirmDialog from '../../utils/DialogBox';
import { useScreenSize } from '../../ScreenSizeContext';

interface Notification {
  id: number;
  message: string;
  date: string;
  posterId: number;
  firstname: string;
  lastname: string;
  important: number;
  area: string;
  fireStation: string;
  role: string;
}

interface Area {
  area: string;
}

interface Role {
  role: string;
}

interface FireStation {
  fire_station: string;
}

const NotificationBoard: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [newMessage, setNewMessage] = useState<string>('');
  const [editMessage, setEditMessage] = useState<string>('');
  const [editNotificationId, setEditNotificationId] = useState<number | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [selectedNotificationId, setSelectedNotificationId] = useState<number | null>(null);
  const area = useSelector((state: RootState) => state.user.area);
  const userId = useSelector((state: RootState) => state.user.id);
  const role = useSelector((state: RootState) => state.user.role);
  const fire_station = useSelector((state: RootState) => state.user.fire_station);
  const [areas, setAreas] = useState<string[]>([]);
  const [roles, setRoles] = useState<string[]>([]);
  const [fireStations, setFireStations] = useState<string[]>([]);
  const [targetAreas, setTargetAreas] = useState<string[]>(['Kaikki']);
  const [targetRoles, setTargetRoles] = useState<string[]>(['Kaikki']);
  const [targetFireStations, setTargetFireStations] = useState<string[]>(['Kaikki']);
  const [sendEmailNotification, setSendEmailNotification] = useState<boolean>(false);
  const { isMobile } = useScreenSize(); // Use isMobile to adjust the layout

  useEffect(() => {
    fetchUniques();
    if (role === 'admin') {
      fetchAllNotifications();
    } else if (area) {
      fetchNotifications(area, role, fire_station);
    }
  }, [area, role, fire_station]);

  const fetchUniques = async () => {
    try {
      const response = await API.get<{ areas: Area[]; roles: Role[]; fireStations: FireStation[] }>('/getUniques');
      setAreas(['Kaikki', ...response.data.areas.map((item: Area) => item.area)]);
      setRoles(['Kaikki', ...response.data.roles.map((item: Role) => item.role)]);
      setFireStations(['Kaikki', ...response.data.fireStations.map((item: FireStation) => item.fire_station)]);
    } catch (error) {
      setSnackbarMessage('Error fetching unique values');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const fetchNotifications = async (area: string, role: string, fireStation: string) => {
    try {
      const response = await API.get('/getMessages', { params: { area, role, fireStation } });
      setNotifications(response.data);
    } catch (error) {
      setSnackbarMessage('Error fetching notifications');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };
  const fetchAllNotifications = async () => {
    try {
      const response = await API.get('/getAllMessages');
      setNotifications(response.data);
    } catch (error) {
      setSnackbarMessage('Error fetching notifications');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handlePostMessage = async () => {
    try {
      const response = await API.post('/postMessage', {
        id: userId,
        message: newMessage,
        targetAreas,
        targetRoles,
        targetFireStations,
        sendEmailNotification,
      });
      setTargetAreas(['Kaikki']);
      setTargetRoles(['Kaikki']);
      setTargetFireStations(['Kaikki']);
      setNewMessage('');
      setSnackbarMessage(response.data.message);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      if (role === 'admin') {
        fetchAllNotifications();
      } else {
        fetchNotifications(area, role, fire_station);
      }
    } catch (error) {
      setSnackbarMessage('Error posting message');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDelete = (id: number) => {
    setSelectedNotificationId(id);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedNotificationId !== null) {
      try {
        const result = await API.delete('/deleteMessage', { params: { id: selectedNotificationId } });
        setSnackbarMessage(result.data.message);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setNotifications(notifications.filter(notification => notification.id !== selectedNotificationId));
      } catch (error) {
        setSnackbarMessage('Error deleting notification');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      setConfirmDialogOpen(false);
      setSelectedNotificationId(null);
    }
  };

  const handleEdit = (id: number, message: string) => {
    setEditNotificationId(id);
    setEditMessage(message);
  };

  const handleSaveEdit = async () => {
    if (editNotificationId !== null) {
      try {
        const response = await API.put('/updateMessage', { id: editNotificationId, message: editMessage });
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setEditNotificationId(null);
        setEditMessage('');
        if (role === 'admin') {
          fetchAllNotifications();
        } else {
          fetchNotifications(area, role, fire_station);
        }
      } catch (error) {
        setSnackbarMessage('Error updating notification');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  const handleCancelEdit = () => {
    setEditNotificationId(null);
    setEditMessage('');
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleTargetAreasChange = (event: SelectChangeEvent<string[]>) => {
    setTargetAreas(event.target.value as string[]);
  };

  const handleTargetRolesChange = (event: SelectChangeEvent<string[]>) => {
    setTargetRoles(event.target.value as string[]);
  };

  const handleTargetFireStationsChange = (event: SelectChangeEvent<string[]>) => {
    setTargetFireStations(event.target.value as string[]);
  };

  return (
    <Box sx={{ padding: isMobile ? 1 : 2 }}>
      <Paper elevation={3} sx={{ padding: isMobile ? 1 : 2, marginBottom: 1 }}>
        <Box mb={isMobile ? 1 : 2} sx={{ display: 'flex', flexDirection: 'column', gap: isMobile ? 1 : 2 }}>
          <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2 }}>
            <TextField
              fullWidth
              label="Uusi ilmoitus"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              sx={{ flex: 1, maxWidth: '100%' }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
              <Checkbox
                checked={sendEmailNotification}
                onChange={(e) => setSendEmailNotification(e.target.checked)}
                color="primary"
                sx={{ transform: 'scale(1.5)' }} // Keeps the checkbox size bigger
              />
              <Typography variant="body1" sx={{ marginLeft: 1 }}>
                Tärkeä
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePostMessage}
              sx={{ height: '56px', width: isMobile ? '100%' : '120px' }} // Same size as earlier
            >
              Lisää
            </Button>
          </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="target-area-label">Alue</InputLabel>
              <Select
                labelId="target-area-label"
                multiple
                value={targetAreas}
                onChange={handleTargetAreasChange}
                renderValue={(selected) => (selected as string[]).join(', ')}
                label="Asemaryhmä"
              >
                {areas.map(area => (
                  <MenuItem key={area} value={area}>
                    <Checkbox checked={targetAreas.indexOf(area) > -1} />
                    <MuiListItemText primary={area} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="target-role-label">Rooli</InputLabel>
              <Select
                labelId="target-role-label"
                multiple
                value={targetRoles}
                onChange={handleTargetRolesChange}
                renderValue={(selected) => (selected as string[]).join(', ')}
                label="Rooli"
              >
                {roles.map(role => (
                  <MenuItem key={role} value={role}>
                    <Checkbox checked={targetRoles.indexOf(role) > -1} />
                    <MuiListItemText primary={role} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="target-fire-station-label">Paloasema</InputLabel>
              <Select
                labelId="target-fire-station-label"
                multiple
                value={targetFireStations}
                onChange={handleTargetFireStationsChange}
                renderValue={(selected) => (selected as string[]).join(', ')}
                label="Paloasema"
              >
                {fireStations.map(station => (
                  <MenuItem key={station} value={station}>
                    <Checkbox checked={targetFireStations.indexOf(station) > -1} />
                    <MuiListItemText primary={station} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {notifications.length === 0 ? (
          <Typography variant="h6" align="center">
            Ei ilmoituksia
          </Typography>
        ) : (
          <List>
            {notifications.map(notification => (
              <ListItem key={notification.id} sx={{ marginBottom: 1, borderRadius: 1 }}>
                <Card sx={{ width: '100%', border: notification.important === 1 ? '1px solid blue' : 'none' }} elevation={3}>
                  <CardContent sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', width: '100%'}}>
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: blue[700] }}>
                          {notification.firstname[0]}
                          {notification.lastname[0]}
                        </Avatar>
                      </ListItemAvatar>
                      {editNotificationId === notification.id ? (
                        <TextField
                          fullWidth
                          value={editMessage}
                          onChange={(e) => setEditMessage(e.target.value)}
                          sx={{ marginRight: 2 }}
                          multiline
                        />
                      ) : (
                        <ListItemText
                          sx={{ paddingRight: 2 }}
                          primary={notification.message}
                          secondary={
                            <>
                              {`Lähettäjä: ${notification.firstname} ${notification.lastname} ${new Date(notification.date).toLocaleString()} `}
                              {notification.important === 1 && (
                                <Typography component="span" sx={{ color: 'blue' }}>
                                  (Tärkeä)
                                </Typography>
                              )}
                              <br />
                              {`Alueet: ${notification.area}, Paloasemat: ${notification.fireStation}, Roolit: ${notification.role}`}
                            </>
                          }
                        />
                      )}
                    </Box>
                    {editNotificationId === notification.id ? (
                      <Box sx={{ display: 'flex', justifyContent: isMobile ? 'flex-end' : 'space-between', width: isMobile ? '100%' : 'auto' }}>
                        <IconButton edge="end" aria-label="save" sx={{ color: blue[700], mr: 1 }} onClick={handleSaveEdit}>
                          <CheckIcon />
                        </IconButton>
                        <IconButton edge="end" aria-label="cancel" sx={{ color: red[700] }} onClick={handleCancelEdit}>
                          <CancelIcon />
                        </IconButton>
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', justifyContent: isMobile ? 'flex-end' : 'space-between', width: isMobile ? '100%' : 'auto' }}>
                        <IconButton edge="end" aria-label="edit" sx={{ color: blue[700], mr: 1 }} onClick={() => handleEdit(notification.id, notification.message)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton edge="end" aria-label="delete" sx={{ color: red[700] }} onClick={() => handleDelete(notification.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </ListItem>
            ))}
          </List>
        )}
      </Paper>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%', padding: 3 }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={handleConfirmDelete}
        title="Vahvista poistaminen"
        contentText="Haluatko varmasti poistaa tämän ilmoituksen?"
        cancelText="Peruuta"
        confirmText="Poista"
        cancelButtonColor='primary'
      />
    </Box>
  );
};

export default NotificationBoard;
