import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, List, ListItem, Paper } from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import ConfirmDialog from '../../utils/DialogBox';

interface AbsenceModalProps {
  isOpen: boolean;
  onClose: () => void;
  onMarkAbsence: (availableDateId: number, absenceReason: string) => void;
  availableDates: {
    available_date_id: number;
    tester_firstname: string;
    tester_lastname: string;
    available_date: string;
    available_startTime: string;
    available_endTime: string;
    is_available: number;
    fire_station: string;
  }[];
}

const AbsenceModal: React.FC<AbsenceModalProps> = ({ isOpen, onClose, onMarkAbsence, availableDates }) => {
  const user = useSelector((state: RootState) => state.user);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [absenceReason, setAbsenceReason] = useState<string>('');

  const handleOpenConfirmDialog = (date: any) => {
    setSelectedDate(date);
    setConfirmDialogOpen(true);
  };

  const handleConfirm = () => {
    if (selectedDate) {
      onMarkAbsence(selectedDate.available_date_id, absenceReason);
      setConfirmDialogOpen(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Lisää poissaolo</DialogTitle>
      <DialogContent>
        <List>
          {availableDates.length > 0 ? (
            availableDates.map((date) => (
              <Paper elevation={3} sx={{ padding: 1, marginBottom: 1 }} key={date.available_date_id}>
                <ListItem>
                  <Typography variant="body2">
                    {moment(date.available_date).format('LL')} ({moment(date.available_startTime, 'HH:mm:ss').format('HH:mm')} - {moment(date.available_endTime, 'HH:mm:ss').format('HH:mm')})
                  </Typography>
                  <Typography variant="caption">
                      {date.tester_firstname} {date.tester_lastname}
                    </Typography>
                    <Typography variant="caption">
                      Paloasema: {date.fire_station}
                    </Typography>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleOpenConfirmDialog(date)}
                    sx={{ ml: 2 }}
                  >
                    Poissaolo
                  </Button>
                </ListItem>
              </Paper>
            ))
          ) : (
            <Typography variant="body2">
              Sinulla ei ole suunniteltuja testauspäiviä
            </Typography>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Sulje
        </Button>
      </DialogActions>
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={handleConfirm}
        title="Vahvista poissaolo"
        contentText={`Oletko varma, että haluat merkitä tämän päivän poissaoloksi? \nPoissaolon asettaminen poistaa kaikki varatut ajat poissaolon ajalta ja lähettää automaattisesti sähköposti-ilmoituksen kaikille poissaolon alle jääville ajan varanneille henkilöille. Jos haluat, voit kirjoittaa alla olevaan kenttään vapaamuotoisen selityksen poissaolosta, joka välitetään sähköposti-ilmoituksen mukana.`}
        cancelText="Peruuta"
        confirmText="Vahvista"
        confirmButtonColor="error"
        showTextField={true}
        textFieldValue={absenceReason}
        setTextFieldValue={setAbsenceReason}
      />
    </Dialog>
  );
};

export default AbsenceModal;
