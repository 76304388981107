import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Drawer, List, ListItem, ListItemText, ListItemIcon, Button } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import Logo from '../../img/K-SPelastuslaitos-logo-vaaka-valkoinen.svg';
import JustLogo from '../../img/K-SPelastuslaitos-logo.svg';
import LogoBlack from '../../img/K-SPelastuslaitos-logo-vaaka-musta.svg';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import InfoIcon from '@mui/icons-material/Info';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ErrorReportModal from '../utils/ErrorReportModal';
import FeedbackModal from '../utils/FeedbackModal';

interface HeaderBarProps {
  userInfo?: React.ReactNode;
  handleLogout?: () => void;
  openRegisterModal?: () => void;
  openUserInfo?: () => void;
  role?: string;
  isMobile?: boolean;
}

const HeaderBar: React.FC<HeaderBarProps> = ({ userInfo, handleLogout, openRegisterModal, openUserInfo, role, isMobile }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false); // State to handle error report modal
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false); // State to handle feedback modal

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerList = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box display="flex" justifyContent="center" p={2}>
        <img src={LogoBlack} alt="K-S Pelastuslaitos Logo" style={{ height: 50, width: 'auto' }} />
      </Box>
      {isMobile && userInfo}
      <List>
        {role && (role === 'admin' || role === 'Esihenkilö' || role === 'Testaaja') && (
          <ListItem button onClick={openRegisterModal}>
            <ListItemIcon>
              <PersonAddIcon />
            </ListItemIcon>
            <ListItemText primary="Lisää uusi käyttäjä" />
          </ListItem>
        )}
        {role && (
          <ListItem button onClick={openUserInfo}>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="Omat tiedot" />
          </ListItem>
        )}
        {role && (
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Kirjaudu ulos" />
          </ListItem>
        )}
        <ListItem>
          <Button
            onClick={() => setFeedbackModalOpen(true)}
            variant="contained"
            color="success"
            fullWidth
            sx={{ marginY: 1 }}
          >
            Anna palautetta
          </Button>
        </ListItem>
        <ListItem>
          <Button
            onClick={() => setErrorModalOpen(true)}
            variant="contained"
            color="error"
            fullWidth
            sx={{  }}
          >
            Virheilmoitus
          </Button>
        </ListItem>

      </List>
    </Box>
  );

  return (
    <>
      <AppBar position="static" color="info">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={isMobile ? JustLogo : Logo} alt="K-S Pelastuslaitos Logo" style={{ height: isMobile ? 40 : 50, marginRight: 16 }} />
          </Box>
          <Typography 
            variant="h6" 
            sx={{ 
              textAlign: 'center',
              flexGrow: 1,
              fontSize: isMobile ? '1rem' : '1.25rem',
              marginRight: role ? (isMobile ? '0' : '1rem') : (isMobile ? '1rem' : '12rem')
            }}
          >
            Keski-Suomen pelastuslaitoksen FireFit-ajanvaraus
          </Typography>
          {!isMobile && userInfo && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {userInfo}
            </Box>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {role && (
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer(true)}
                color="inherit"
                size="large"
              >
                <AccountCircle />
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerList}
      </Drawer>
      <ErrorReportModal
        isMobile={isMobile}
        isOpen={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
      />
      <FeedbackModal
        isMobile={isMobile}
        isOpen={feedbackModalOpen}
        onClose={() => setFeedbackModalOpen(false)}
      />
    </>
  );
};

export default HeaderBar;
