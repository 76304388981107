import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import moment from 'moment';
import 'moment/locale/fi';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import ReservationDetailsModal from '../../ReservationDetailsModal';
import fiLocale from '@fullcalendar/core/locales/fi';
import { Box, Button, Typography, Snackbar, Alert } from '@mui/material';
import { useScreenSize } from '../../ScreenSizeContext';
import BookingFormModal from './BookingFormModal';
import AbsenceModal from './AbsenceModal';
import API from '../../API/API';
import { removeAvailableDate, deleteBooking } from '../../../slices/bookingSlice';

moment.locale('fi');

interface Booking {
  id: string;
  start: string;
  end: string;
  title: string;
  area: string;
  userId: string;
  userRole?: string | null;
  firstname?: string;
  lastname?: string;
  phonenumber?: string;
  fire_station?: string;
  tester?: string;
  approved: number;
}

interface AvailableDate {
  available_date_id: number;
  tester_firstname: string;
  tester_lastname: string;
  available_date: string;
  available_startTime: string;
  available_endTime: string;
  is_available: number;
  fire_station: string;
}

interface BookingCalendarProps {
  area: string | null;
  role: string | null;
}

interface BackgroundEvent {
  start: string;
  end: string;
  display: string;
  color: string;
}

const BookingCalendar: React.FC<BookingCalendarProps> = ({ area, role }) => {
  const dispatch = useDispatch();
  const calendarRef = useRef<FullCalendar | null>(null);
  const bookings = useSelector((state: RootState) => state.bookings.bookings || []);
  const availableDates = useSelector((state: RootState) => state.bookings.availableDates || []);
  const [reservationDetailsModalOpen, setReservationDetailsModalOpen] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState<Booking | null>(null);
  const [currentView, setCurrentView] = useState('dayGridMonth');
  const [bookingModalOpen, setBookingModalOpen] = useState(false); // New state for booking modal
  const { isMobile, isTablet } = useScreenSize();
  const [absenceModalOpen, setAbsenceModalOpen] = useState(false);
  const [backgroundEvents, setBackgroundEvents] = useState<BackgroundEvent[]>([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const handleMarkAbsence = async (availableDateId: number, absenceReason: string) => {
    const absenceBody = {
      availableDateId: availableDateId,
      absenceReason: absenceReason
    }
    try {
      const result = await API.post('/mark-absence', absenceBody);
       // Iterate over the deletedBookingIds array and dispatch deleteBooking action for each ID
      result.data.deletedBookingIds.forEach((id: string) => {
        dispatch(deleteBooking(id));
      });
      dispatch(removeAvailableDate(availableDateId)); // remove availableDate from the store
      setSnackbarMessage(result.data.message);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);

    } catch (error: any) {
      setSnackbarMessage(error.data.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error(error);
    }
    setAbsenceModalOpen(false);
  };

  const getBackgroundEvents = (availableDates: AvailableDate[]): BackgroundEvent[] => {
    const events: BackgroundEvent[] = [];
    const groupedAvailability: { [key: string]: { startTimes: string[], endTimes: string[] } } = {};
  
    availableDates.forEach((available: AvailableDate) => {
      const date = moment(available.available_date).format('YYYY-MM-DD');
  
      if (!groupedAvailability[date]) {
        groupedAvailability[date] = { startTimes: [], endTimes: [] };
      }
  
      groupedAvailability[date].startTimes.push(available.available_startTime);
      groupedAvailability[date].endTimes.push(available.available_endTime);
    });
  
    Object.keys(groupedAvailability).forEach(date => {
      const { startTimes, endTimes } = groupedAvailability[date];
      startTimes.sort();
      endTimes.sort();
  
      if (startTimes[0] !== "00:00:00") {
        events.push({
          start: `${date}T00:00:00`,
          end: `${date}T${startTimes[0]}`,
          display: 'background',
          color: '#fd8a8a'
        });
      }
  
      for (let i = 0; i < endTimes.length - 1; i++) {
        if (endTimes[i] !== startTimes[i + 1]) {
          events.push({
            start: `${date}T${endTimes[i]}`,
            end: `${date}T${startTimes[i + 1]}`,
            display: 'background',
            color: '#fd8a8a'
          });
        }
      }
  
      if (endTimes[endTimes.length - 1] !== "23:59:59") {
        events.push({
          start: `${date}T${endTimes[endTimes.length - 1]}`,
          end: `${date}T23:59:59`,
          display: 'background',
          color: '#fd8a8a'
        });
      }
    });
  
    return events;
  };


  useEffect(() => {
    const backgroundEvents = getBackgroundEvents(availableDates);
    setBackgroundEvents(backgroundEvents);
  }, [availableDates]);

  const handleEventClick = (arg: any) => {  
    const bookingId = arg.event.id;
  
    const booking = bookings.find((b) => {
      return b.id.toString() === bookingId;
    });
  
    if (booking) {
      setSelectedReservation(booking);
      setReservationDetailsModalOpen(true);
    } else {
      console.warn('No booking found with event id:', bookingId);
    }
  };

  const handleDateClick = (arg: any) => {
    const calendarApi = calendarRef.current?.getApi();
    if (currentView === 'dayGridMonth') {
      calendarApi?.changeView('timeGridDay', arg.date);
      return;
    }
  };

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
        calendarApi.on('datesSet', () => {
            setCurrentView(calendarApi.view.type);
        });
    }
  }, []);

  const eventContent = (eventInfo: any) => {
    const approved = eventInfo.event.extendedProps.approved;
    let backgroundColor;
  
    if (eventInfo.event.title === 'Varattu' || approved === 2) {
      backgroundColor = '#C62828'; 
    } else if (approved === 1) {
      backgroundColor = '#2E7D32'; 
    } else if (approved === 0) {
      backgroundColor = '#1565C0'; 
    }
  
    return (
      <Box
        sx={{
          backgroundColor: backgroundColor,
          color: 'white',
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          px: 1, // padding left and right for better spacing
        }}
      >
        <Typography variant="body2" component="b" sx={{ pr: 1 }}>
          {eventInfo.timeText}
        </Typography>
        {!isMobile && (
          <Typography variant="caption" component="i">
            {eventInfo.event.title}
          </Typography>
        )}
      </Box>
    );
  };

  const dayCellClassNames = (date: any) => {
    const isAvailable = availableDates.some((available) =>
      moment(available.available_date).isSame(date.date, 'day')
    );

    return isAvailable ? '' : 'unavailable-day';
  };

  const eventClassNames = (event: any) => {
    const isAvailable = availableDates.some((available) =>
      moment(available.available_date).isSame(event.event.start, 'day')
    );

    return isAvailable ? '' : 'unavailable-event';
  };



  // Define a static event for the lunch break
  const lunchBreakEvent = {
    id: 'lunchBreak',
    title: 'Tauko',
    startTime: '11:00:00',
    endTime: '12:00:00',
    display: 'background',
    color: '#ffd700', // Gold color for lunch break
  };

  return (
    <Box 
      sx={{
        height: isMobile ? '600px' : isTablet ? '600px' : '800px',
        overflow: 'auto',
        '& .fc': {
          flex: 1,
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
        '& .fc-toolbar': {
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          marginBottom: '1em',
        },
        '& .fc-toolbar-title': {
          fontSize: isMobile ? '1rem' : isTablet ? '1.2rem' : '1.5rem',
        },
        '& .fc-button': {
          padding: isMobile ? '0.3rem 0.6rem' : isTablet ? '0.4rem 0.8rem' : '0.5rem 1rem',
          fontSize: isMobile ? '0.75rem' : isTablet ? '0.8rem' : '0.875rem',
          margin: isMobile ? '0.2rem' : isTablet ? '0.4rem 0.8rem' : '0.5rem 1rem',
          backgroundColor: '#0288d1',
          borderColor: '#007bff',
          color: 'white',
          borderRadius: '10px',
          marginRight: '8px',
        },
        '& .fc-button:hover': {
          backgroundColor: '#0056b3',
          borderColor: '#0056b3',
          color: 'white',
        },
        '& .fc-button.fc-button-active': {
          backgroundColor: '#004085',
          borderColor: '#004085',
          color: 'white',
        },
        '& .fc-button-group': {
          display: 'flex',
          alignItems: 'center',
        },
        '& .fc .fc-toolbar-chunk': {
          display: 'flex',
          alignItems: 'center',
        },
        '& .fc-daygrid-day': {
          flex: 1,
          boxSizing: 'border-box',
          padding: '2px',
          height: 'calc(100% / 6)',
        },
        '& .fc-daygrid-day-frame': {
          minHeight: '30px',
          boxSizing: 'border-box',
        },
        '& .fc-daygrid-day-top': {
          display: 'flex',
          justifyContent: 'center',
        },
        '& .fc-daygrid-day-bottom': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        },
        '& .fc-daygrid': {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        },
        '& .fc-daygrid-week': {
          flex: 1,
        },
        '& .fc-daygrid-week-row': {
          display: 'flex',
          flex: 1,
          minHeight: 0,
        },
        '& .fc-bg-event': {
          opacity: 0.8,
        },
        '& .fc-timegrid-col[data-date*="Sat"], .fc-timegrid-col[data-date*="Sun"]': {
          backgroundColor: 'white !important',
          opacity: '1 !important',
        },
        '& .green-background': {
          backgroundColor: 'rgb(2, 165, 2) !important',
          color: 'rgb(255, 255, 255) !important',
        },
        '& .green-background-day': {
          backgroundColor: 'green !important',
          color: 'white',
        },
        '& .unavailable-day': {
          backgroundColor: '#fd8a8a !important',
          opacity: 0.8,
        },
        '& .unavailable-event': {
          backgroundColor: '#fd8a8a !important',
          color: 'white !important',
        },
        '& .unavailable-time': {
          backgroundColor: '#fd8a8a !important',
        },
        '& .fc-timegrid-slot-lane.unavailable-slot': {
          backgroundColor: '#fd8a8a',
        },
      }}
    >
      <Button 
        variant="contained" 
        color="success" 
        onClick={() => setBookingModalOpen(true)}
        sx={{ mb: 2 }}
      >
        Varaa uusi aika
      </Button>
      {role === 'Testaaja' && (
        <Button 
          variant="contained" 
          color="error" 
          onClick={() => setAbsenceModalOpen(true)}
          sx={{ mb: 2, ml: 2 }}
        >
          Lisää poissaolo
        </Button>
      )}

      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, momentTimezonePlugin]}
        initialView={currentView}
        ref={calendarRef}
        timeZone='UTC'
        events={[
          ...bookings.map((booking) => ({
            ...booking,
            start: booking.start,
            end: booking.end,
          })),
          ...backgroundEvents,
          {
            ...lunchBreakEvent,
            daysOfWeek: [1, 2, 3, 4, 5, 6, 7], // Monday to Sunday
          },
        ]}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        eventContent={eventContent}
        locale={fiLocale}
        dayCellClassNames={dayCellClassNames}
        eventClassNames={eventClassNames}
        slotMinTime='08:00:00'
        slotMaxTime='23:00:00'
        slotLabelFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }}
        allDaySlot={false}
        height="100%"
        dayMaxEvents={2}
      />
      {selectedReservation && (
        <ReservationDetailsModal
          isOpen={reservationDetailsModalOpen}
          onClose={() => setReservationDetailsModalOpen(false)}
          reservation={selectedReservation}
        />
      )}
      {bookingModalOpen && (
        <BookingFormModal
          isOpen={bookingModalOpen}
          onRequestClose={() => setBookingModalOpen(false)}
          onSubmit={() => {
            setBookingModalOpen(false);
          }}
          selectedStartTime="" // You can set default start time if needed
          selectedEndTimeStr="" // You can set default end time if needed
          role={role}
          availableDates={availableDates}
          existingBookings={bookings}
        />
      )}
      <AbsenceModal
        isOpen={absenceModalOpen}
        onClose={() => setAbsenceModalOpen(false)}
        onMarkAbsence={handleMarkAbsence}
        availableDates={availableDates}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
    
  );
};

export default BookingCalendar;
