import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, TextField, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem } from '@mui/material';
import moment from 'moment';
import API from '../../API/API';

interface EventDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  initialData: { start: Date; end: Date; fireStation: string; testerId: string; firstname: string; lastname: string, area: string; };
  isFullDay: boolean;
  isEditMode: boolean;
}
interface Option {
  value: string;
  label: string;
  area?: string;
}

const EventDetailModal: React.FC<EventDetailModalProps> = ({ isOpen, onClose, onSave, initialData, isFullDay, isEditMode }) => {
  const [fireStation, setFireStation] = useState(initialData.fireStation);
  const [startTime, setStartTime] = useState(moment(initialData.start).format('HH:mm:ss'));
  const [endTime, setEndTime] = useState(moment(initialData.end).format('HH:mm:ss'));
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false); // State to control the confirmation dialog
  const [fireStations, setFireStations] = useState<Option[]>([]);
  const [areaOptions, setAreaOptions] = useState<Option[]>([]);
  const [filteredFireStations, setFilteredFireStations] = useState<Option[]>([]);
  const [selectedArea, setSelectedArea] = useState(initialData.area);

  const getFireStations = async () => {
    try {
      const result = await API.get('/getOptions');
      setFireStations(result.data.fireStations);
      setAreaOptions(result.data.areas);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getFireStations();
  }, [isOpen]);

  useEffect(() => {
    const filtered = fireStations.filter(station => station.area === selectedArea);
    setFilteredFireStations(filtered);
  }, [selectedArea, fireStations]);

  // Set default times if the event is a full day event
  useEffect(() => {
    if (isFullDay) {
      setStartTime('09:00:00');
      setEndTime('16:00:00');
    }
  }, [isFullDay]);

  const handleSave = async () => {
    const date = moment(initialData.start).format('YYYY-MM-DD');
    const originalStartTime = moment(initialData.start).format('HH:mm:ss');
    const newStartTime = isFullDay ? startTime : originalStartTime;
  
    // Find the selected fire station to get the correct area
    const selectedStation = fireStations.find(station => station.value === fireStation);
    const area = selectedStation ? selectedStation.area : initialData.area;
  
    const payload = {
      testerId: initialData.testerId,
      date: date,
      start_time: newStartTime,
      new_start_time: startTime,
      end_time: endTime,
      fireStation: fireStation,
      isAvailable: 1,
      area: area // Use the area from the selected fire station
    };
  
    try {
      if (isEditMode) {
        await API.post('/availability/update', payload); // POST request for updating an existing event
      } else {
        await API.post('/availability', payload); // POST request for creating a new event
      }
      onSave();
      onClose();
    } catch (error) {
      console.error('Failed to save availability', error);
    }
  };

  const handleDelete = async () => {
    const date = moment(initialData.start).format('YYYY-MM-DD');
    const startTime = moment(initialData.start).format('HH:mm:ss');

    try {
      await API.delete(`/availability/${initialData.testerId}/${date}/${startTime}`); // DELETE request to remove the event
      onSave();
      onClose();
    } catch (error) {
      console.error('Failed to remove availability', error);
    }
  };

  const handleOpenConfirmDelete = () => {
    setConfirmDeleteOpen(true);
  };

  const handleCloseConfirmDelete = () => {
    setConfirmDeleteOpen(false);
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            p={3}
            bgcolor="white"
            borderRadius={4}
            width="90%"
            maxWidth="400px"
          >
            <Typography variant="h6" mb={2}>
              {isEditMode ? 'Muokkaa testausaikaa' : 'Lisää testausaika'}
            </Typography>
            <Typography variant="subtitle1" mb={2}>
              {`Testaaja: ${initialData.firstname} ${initialData.lastname}`}
            </Typography>

            <Select
              value={selectedArea}
              onChange={(e) => setSelectedArea(e.target.value)}
              fullWidth
              displayEmpty
              sx={{marginBottom: '15px'}}
            >
              <MenuItem value="" disabled>
                Valitse alue
              </MenuItem>
              {areaOptions.map((area) => (
                <MenuItem key={area.value} value={area.value}>
                  {area.label}
                </MenuItem>
              ))}
            </Select>
            <Select
              value={fireStation}
              onChange={(e) => setFireStation(e.target.value)}
              fullWidth
              displayEmpty
            >
              <MenuItem value="" disabled>
                Valitse paloasema
              </MenuItem>
              {filteredFireStations.map((station) => (
                <MenuItem key={station.value} value={station.value}>
                  {station.label}
                </MenuItem>
              ))}
            </Select>
            <TextField
              label="Aloitusaika"
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Lopetusaika"
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Box mt={2} display="flex" justifyContent="space-between" width="100%">
              <Button variant="contained" color="success" onClick={handleSave}>
                {isEditMode ? 'Päivitä' : 'Tallenna'}
              </Button>
              <Button color="primary" onClick={onClose}>
                Sulje
              </Button>
              {isEditMode && (
                <Button color="error" onClick={handleOpenConfirmDelete}>
                  Poista
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Confirmation Dialog for Deletion */}
      <Dialog
        open={confirmDeleteOpen}
        onClose={handleCloseConfirmDelete}
      >
        <DialogTitle>Vahvista poistaminen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Oletko varma, että haluat poistaa tämän tapahtuman? Tämä toiminto on peruuttamaton.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDelete} color="primary">
            Peruuta
          </Button>
          <Button onClick={handleDelete} variant="contained" color="error">
            Poista
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EventDetailModal;
