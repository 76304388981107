import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import bookingReducer from './slices/bookingSlice';
import userReducer from './slices/userSlice';
import encryptedLocalStorage from './encryptedLocalStorage';

// Combine your reducers
const rootReducer = combineReducers({
  bookings: bookingReducer,
  user: userReducer,
});

// Configure persist
const persistConfig = {
  key: 'root',
  version: 1,
  storage: encryptedLocalStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Create a persistor
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
