import React from 'react';
import { Paper, Typography } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';

interface BookingTrendsProps {
  labels: string[];
  data: number[];
}

const BookingTrends: React.FC<BookingTrendsProps> = ({ labels, data }) => {
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Varaukset',
        backgroundColor: '#36A2EB',
        borderColor: '#36A2EB',
        data: data,
      },
    ],
  };
  const now = moment().format('YYYY');

  return (
    <Paper elevation={3} style={{ padding: '16px' }}>
      <Typography variant="h6" gutterBottom>
        Varaukset {now}
      </Typography>
      <Bar data={chartData} />
    </Paper>
  );
};

export default BookingTrends;
