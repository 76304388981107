import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import RegisterModal from '../Register/RegisterModal';
import ChangePasswordModal from './ChangePasswordModal';
import UserInfo from './UserInfo';
import HeaderBar from './HeaderBar'; // Import HeaderBar component
import { Box, Typography } from '@mui/material';
import API from '../API/API';
import { useScreenSize } from '../ScreenSizeContext'; // Import the useScreenSize hook
import { clearBookingState } from '../../slices/bookingSlice';
import { clearUserState } from '../../slices/userSlice';
import CookieConsentBanner from '../CookieConsentBanner';

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const { isMobile } = useScreenSize(); // Get screen size info
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const [userInfoOpen, setUserInfoOpen] = useState(false);
  const userId = useSelector((state: RootState) => state.user.id);
  const dispatch = useDispatch();

  const handleLogout = async () => {
    // Clear local storage and state immediately
    localStorage.removeItem('accessToken');
    dispatch(clearBookingState());
    dispatch(clearUserState());
    
    // Navigate to the login page immediately
    navigate('/login');
    
    // Then perform the API call
    try {
      await API.post('/logout', { userId });
    } catch (error) {
      console.log('Logout failed', error);
    } finally {
      // Reload the page to ensure a fresh state
      window.location.reload();
    }
  };
  const openRegisterModal = () => setIsRegisterModalOpen(true);
  const closeRegisterModal = () => setIsRegisterModalOpen(false);
  const openChangePasswordModal = () => setIsChangePasswordModalOpen(true);
  const closeChangePasswordModal = () => setIsChangePasswordModalOpen(false);
  const openUserInfo = () => setUserInfoOpen(true);
  const closeUserInfo = () => setUserInfoOpen(false);

  const { firstname, lastname, area, role, email } = useSelector((state: RootState) => state.user);

  const userInfo = (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', py: 1, px: 3 }}>
      {firstname && lastname ? (
        <>
          <Typography variant="body1" sx={{ mb: 1 }}>
            {firstname} {lastname}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Alue: {area}
          </Typography>
        </>
      ) : (
        <Typography variant="body1" sx={{ mb: 1 }}>
          Ladataan käyttäjätietoja...
        </Typography>
      )}
    </Box>
  );
  

  return (
    <Box className="layout" sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <HeaderBar
        userInfo={userInfo}
        handleLogout={handleLogout}
        openRegisterModal={openRegisterModal}
        openUserInfo={openUserInfo}
        role={role}
        isMobile={isMobile}
      />
      <Box sx={{ width: '100%', bgcolor: '#ffecb3', borderBottom: '2px solid #ff9800', textAlign: 'center' }}>
        <Typography 
          variant="body1" 
          sx={{ 
            fontWeight: 'bold', 
            color: '#e65100',
            fontSize: isMobile ? '0.875rem' : '1rem', // Adjust font size based on isMobile
          }}
        >
          Tämä sovellus on testivaiheessa. Käyttökatkoja ja muutoksia käyttöliittymään voi esiintyä.
        </Typography>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, width: '100%' }}>
        {children}
      </Box>
      <RegisterModal isOpen={isRegisterModalOpen} onClose={closeRegisterModal} userRole={role} />
      <ChangePasswordModal
        isOpen={isChangePasswordModalOpen}
        onRequestClose={closeChangePasswordModal}
        email={email} // Pass the email from the user state
      />
      <UserInfo open={userInfoOpen} onClose={closeUserInfo} onOpenChangePassword={openChangePasswordModal} />
      <CookieConsentBanner />
    </Box>
  );
};

export default Layout;
