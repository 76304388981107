// Utility function to check if the current date is within one year of the test expiration date
const isWithinNextYear = (dateString: string, goal: string): { isValid: boolean, expirationDate: string } => {
    const expirationDate = new Date(dateString);
    const now = new Date();

    let validityRangeInYears = 1;// default
    if (goal === 'Perus') {
      validityRangeInYears = 2;
    }
  
    // Calculate the test date (one year before the expiration date)
    const testDate = new Date(expirationDate);
    testDate.setFullYear(expirationDate.getFullYear() - validityRangeInYears);
    //console.log('testDate: ',testDate);
  
    const isValid = now >= testDate && now <= expirationDate;

    //console.log('isValid',isValid);
  
    return {
      isValid,
      expirationDate: expirationDate.toLocaleDateString(),
    };
  };
  
  export default isWithinNextYear;
  