import React, { useState } from 'react';
import { Paper, Typography, List, ListItem, ListItemText, ListItemButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import moment from 'moment';

interface Alert {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  phonenumber: string;
  testType: string;
  expiryDate: string;
}

interface TestExpiryAlertsProps {
  alerts: Alert[];
}

const TestExpiryAlerts: React.FC<TestExpiryAlertsProps> = ({ alerts }) => {
  const [selectedUser, setSelectedUser] = useState<Alert | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClick = (alert: Alert) => {
    setSelectedUser(alert);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedUser(null);
  };

  const now = moment().format('DD.MM.YYYY');
  const oneMonthFromNow = moment().add(1, 'month').format('DD.MM.YYYY');

  return (
    <>
      <Paper elevation={3} style={{ padding: '16px' }}>
        <Typography variant="h6" gutterBottom>
          Vanhenevat testit ({now} - {oneMonthFromNow})
        </Typography>
        <List style={{ padding: '16px', maxHeight: '200px', overflow: 'auto' }}>
          {alerts.map((alert, index) => (
            <ListItemButton key={index} onClick={() => handleClick(alert)}>
              <ListItemText
                primary={`${alert.firstname} ${alert.lastname} - ${alert.testType}`}
                secondary={`Vanhentuu: ${new Date(alert.expiryDate).toLocaleDateString()}`}
              />
            </ListItemButton>
          ))}
        </List>
      </Paper>

      {selectedUser && (
        <Dialog open={dialogOpen} onClose={handleClose}>
          <DialogTitle>{`${selectedUser.firstname} ${selectedUser.lastname}`}</DialogTitle>
          <DialogContent>
              <Typography variant="body1"><strong>Sähköposti:</strong> {selectedUser.email}</Typography>
              <Typography variant="body1"><strong>Puhelinnumero:</strong> {selectedUser.phonenumber}</Typography>
              <Typography variant="body1"><strong>Testi:</strong> {selectedUser.testType}</Typography>
              <Typography variant="body1"><strong>Vanhenee:</strong> {new Date(selectedUser.expiryDate).toLocaleDateString()}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Sulje
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default TestExpiryAlerts;
