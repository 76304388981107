import React from 'react';
import { ListItem, Grid, Typography } from '@mui/material';
import { getStatus, getStatusStyles } from '../../utils/statusConverterFunctions';

interface BookingItemProps {
  booking: {
    id: string;
    start: string;
    end: string;
    title: string;
    fire_station: string;
    firstname: string;
    lastname: string;
    approved: number;
  };
}

const BookingItem: React.FC<BookingItemProps> = ({ booking }) => {
  const startDate = new Date(booking.start);
  const endDate = new Date(booking.end);

  return (
    <ListItem>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item xs={1.5} container justifyContent="center">
          <Typography>{booking.title}</Typography>
        </Grid>
        <Grid item xs={1.5} container justifyContent="center">
          <Typography>{booking.firstname} {booking.lastname}</Typography>
        </Grid>
        <Grid item xs={1.5} container justifyContent="center">
          <Typography>{booking.fire_station}</Typography>
        </Grid>
        <Grid item xs={1.5} container justifyContent="center">
          <Typography>{startDate.toLocaleDateString()}</Typography>
        </Grid>
        <Grid item xs={1.5} container justifyContent="center">
          <Typography>{startDate.toLocaleTimeString()}</Typography>
        </Grid>
        <Grid item xs={1.5} container justifyContent="center">
          <Typography>{endDate.toLocaleTimeString()}</Typography>
        </Grid>
        <Grid item xs={1.5} container justifyContent="center">
          <Typography
            sx={{
              color: 'white',
              backgroundColor: getStatusStyles(booking.approved).backgroundColor,
              borderRadius: '4px',
              padding: '4px 6px',
              display: 'inline-block',
            }}
          >
            {getStatus(booking.approved)}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default BookingItem;
