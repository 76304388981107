import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { DateSelectArg, EventClickArg, EventContentArg } from '@fullcalendar/core';
import API from '../../API/API';
import { Box } from '@mui/material';
import fiLocale from '@fullcalendar/core/locales/fi';
import moment from 'moment';
import EventDetailModal from './EventDetailModal';
import { useScreenSize } from '../../ScreenSizeContext';

interface EventData {
  id: string;
  title: string;
  start: string;
  end: string;
  is_available: boolean;
  fireStation?: string;
  classNames?: string[];
}

interface SchedulerProps {
  testerId: string;
  firstname: string;
  lastname: string;
  area: string;
}

interface SelectedSlot {
  start: Date;
  end: Date;
  fireStation?: string;
  isFullDay?: boolean;
  tester: string;
  firstname: string;
  lastname: string;
  area: string;
}

const Scheduler: React.FC<SchedulerProps> = ({ testerId, firstname, lastname, area }) => {
  const [events, setEvents] = useState<EventData[]>([]);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [tempSlot, setTempSlot] = useState<SelectedSlot | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const { isMobile, isTablet } = useScreenSize();

  const fetchEvents = async (testerId: string) => {
    try {
      const response = await API.get(`/availability/${testerId}`);
      const fetchedEvents = response.data.map((event: any) => ({
        id: event.id,
        title: event.is_available ? `Suunniteltu testausaika` : 'Poissaolo',
        start: event.start_time ? `${event.date}T${event.start_time}` : event.date,
        end: event.end_time ? `${event.date}T${event.end_time}` : event.date,
        is_available: event.is_available,
        fireStation: event.fire_station,
        classNames: event.is_available ? ['green-background'] : ['red-background']
      }));
      setEvents(fetchedEvents);
    } catch (error) {
      console.error('Failed to fetch events', error);
    }
  };

  useEffect(() => {
    fetchEvents(testerId);
  }, [testerId, firstname, lastname]);

  const renderEventContent = (eventContent: EventContentArg) => {
    const { event } = eventContent;
    const startTime = moment(event.start).format('HH:mm');
    const endTime = moment(event.end).format('HH:mm');

    return (
      <div>
        <div>{`${startTime} - ${endTime}`}</div>
        <div>{event.title}</div>
        <div>{`henkilölle`}</div>
        <div>{`${firstname} ${lastname}`}</div>
        <div>{`Paloasema: ${event.extendedProps.fireStation || ''}`}</div>
      </div>
    );
  };

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    const start = moment(selectInfo.startStr).toDate();
    const end = moment(selectInfo.endStr).toDate();
    const isFullDay = selectInfo.allDay;
    const tester = testerId;
    setTempSlot({ start, end, isFullDay, tester, firstname, lastname, area });
    setIsEditMode(false); // This is a new event
    setOpenDetailModal(true);
  };

  const handleEventClick = async (clickInfo: EventClickArg) => {
    const event = clickInfo.event;
    const startTime = moment(event.start).format('HH:mm:ss');
    const date = moment(event.start).format('YYYY-MM-DD');
  
    // Check if event.start and event.end are not null
    if (!event.start || !event.end) {
      console.error('Event start or end date is null');
      return;
    }
  
    try {
      const response = await API.get(`/availability/${testerId}/${date}/${startTime}`);
      const eventData = response.data;
  
      setTempSlot({
        start: event.start,
        end: event.end,
        fireStation: eventData.fire_station,
        isFullDay: event.allDay,
        tester: testerId,
        firstname: firstname,
        lastname: lastname,
        area: area,
      });
      setIsEditMode(true); // This is an existing event
      setOpenDetailModal(true);
    } catch (error) {
      console.error('Failed to fetch availability', error);
    }
  };

  const dayCellClassNames = (date: any) => {
    const dayEvents = events.filter(event => moment(event.start).isSame(date, 'day'));
    return dayEvents.length > 0 ? ['green-background-day'] : [];
  };

  return (
    <Box 
    sx={{ 
      height: isMobile ? '500px' : isTablet ? '600px' : '800px', 
      overflow: 'auto', 
      padding: 2,
      '& .fc-toolbar': {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      },
      '& .fc-toolbar-title': {
        fontSize: isMobile ? '1rem' : isTablet ? '1.1rem' : '1.2rem',
      },
      '& .fc-event': {
        /* width: '90% !important', */      // Make event width flexible to prevent overlap
        maxWidth: '90% !important',   // Prevent the event from exceeding its column width
        overflow: 'hidden',            // Hide overflow content to avoid spilling into other slots
        whiteSpace: 'nowrap',          // Prevent text from wrapping into multiple lines
        textOverflow: 'ellipsis',      // Show ellipsis (...) if the event title is too long
        padding: '2px 4px',            // Adjust padding for better spacing
      },
      '& .fc-event:hover': {
        overflow: 'visible',           // Allow overflow when hovering (optional)
        cursor: 'pointer',
      },
      '& .fc-daygrid-event': {
        width: '100% !important',      // Ensure day grid events also follow the same rule
      },
      '& .fc-button': {
        padding: isMobile ? '0.3rem 0.6rem' : isTablet ? '0.4rem 0.8rem' : '0.5rem 1rem',
        fontSize: isMobile ? '0.75rem' : isTablet ? '0.8rem' : '0.875rem',
        margin: isMobile ? '0.2rem' : isTablet ? '0.4rem 0.8rem' : '0.5rem 1rem',
        backgroundColor: '#0288d1',
        borderColor: '#007bff',
        color: 'white',
        borderRadius: '10px',
        marginRight: '8px',
      },
      '& .fc-button:hover': {
        backgroundColor: '#0056b3',
        borderColor: '#0056b3',
        color: 'white',
      },
      '& .fc-button.fc-button-active': {
        backgroundColor: '#004085',
        borderColor: '#004085',
        color: 'white',
      },
      '& .fc-button-group': {
        display: 'flex',
        alignItems: 'center',
      },
      '& .fc .fc-toolbar-chunk': {
        display: 'flex',
        alignItems: 'center',
      },
      '& .green-background': {
        backgroundColor: 'rgb(2, 165, 2) !important',
        color: 'rgb(255, 255, 255) !important',
      },
      '& .green-background-day': {
        backgroundColor: 'rgb(2, 165, 2) !important',
        color: 'white',
      },
      '& .red-background': {
        backgroundColor: 'rgb(198, 40, 40) !important',
        color: 'rgb(255, 255, 255) !important',
      },
    }} 
    className="calendar-container-dashboard"
    >
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        selectable={true}
        /* editable={true} */
        selectMirror={true}
        weekends={true}
        events={events}
        select={handleDateSelect}
        eventClick={handleEventClick}
        eventContent={renderEventContent}
        locale={fiLocale}
        slotMinTime="08:00:00"
        slotMaxTime="21:00:00"
        dayCellClassNames={dayCellClassNames}
        height='100%'
        longPressDelay={100}
      />

      {openDetailModal && tempSlot && (
        <EventDetailModal
          isOpen={openDetailModal}
          onClose={() => setOpenDetailModal(false)}
          onSave={() => fetchEvents(testerId)}
          initialData={{
            start: tempSlot.start,
            end: tempSlot.end,
            fireStation: tempSlot.fireStation || '',
            testerId: tempSlot.tester,
            firstname: tempSlot.firstname || '',
            lastname: tempSlot.lastname || '',
            area: tempSlot.area || '',
          }}
          isFullDay={!!tempSlot.isFullDay}
          isEditMode={isEditMode}
        />
      )}
    </Box>
  );
};

export default Scheduler;
