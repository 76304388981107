// FilterComponent.tsx
import React from 'react';
import {
  Box,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  IconButton,
} from '@mui/material';
import { Clear } from '@mui/icons-material';

interface FilterComponentProps {
  filter: { [key: string]: string };
  fields: Array<{ label: string; name: string; type: string; options?: string[] }>;
  handleFilterChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => void;
  handleClearFilters: () => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({ filter, fields, handleFilterChange, handleClearFilters }) => {
  const isFilterActive = Object.values(filter).some(value => value !== '');

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center" m={2}>
      {fields.map(field => {
        if (field.type === 'text') {
          return (
            <TextField
              key={field.name}
              label={field.label}
              name={field.name}
              value={filter[field.name]}
              onChange={handleFilterChange}
              style={{ marginRight: '8px' }}
            />
          );
        } else if (field.type === 'select' && field.options) {
          return (
            <FormControl key={field.name} style={{ minWidth: '120px', marginRight: '8px' }}>
              <InputLabel>{field.label}</InputLabel>
              <Select
                name={field.name}
                value={filter[field.name]}
                onChange={handleFilterChange}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {field.options.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        } else if (field.type === 'date') {
          return (
            <TextField
              key={field.name}
              label={field.label}
              type="date"
              name={field.name}
              InputLabelProps={{ shrink: true }}
              value={filter[field.name]}
              onChange={handleFilterChange}
              style={{ marginRight: '8px' }}
            />
          );
        }
        return null;
      })}
      {isFilterActive && (
        <IconButton onClick={handleClearFilters}>
          <Clear sx={{backgroundColor: 'red', borderRadius: '30px', padding: '5px', color: 'white'}}/>
        </IconButton>
      )}
    </Box>
  );
};

export default FilterComponent;
