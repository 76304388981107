import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import API from '../../API/API';
import UserDistribution from './UserDistributionTable';
import UniversalStatsCard from './UniversalStatsCard';
import BookingTrends from './BookingTrendsTable';
import TestExpiryAlerts from './TestExpiryAlerts';
import TestExpiryTrends from './TestExpiryTrendsTable';
import Loading from '../../Loading/Loading';
import BookingLineGraph from './BookingVsAvailableLineGraph';
import UserIndexDistributionTable from './UserIndexDistributionTable';

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
} from 'chart.js';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title
);

interface DashboardTabOverviewProps {
  role: string;
  area: string;
}

interface DeletedBooking {
  booking_title: string;
  delete_cause: string;
  deletionDate: string;
  start: string;
  end: string;
  id: number;
}

const DashboardTabOverview: React.FC<DashboardTabOverviewProps> = ({ role, area }) => {
  const [statistics, setStatistics] = useState<any>(null);

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await API.get('/statistics', {
          params: { role, area }
        });
        setStatistics(response.data);
      } catch (error) {
        console.error('Error fetching statistics:', error);
      }
    };

    fetchStatistics();
  }, [role, area]);

  if (!statistics) {
    return <Loading />;
  }
  //console.log(statistics);

  const regularUsers = statistics.userDistribution[0];
  const validErgometry = statistics.validErgometry;
  const validFitnessTest = statistics.validFitnessTests;
  const validOulunmalliTests = statistics.validOulunmalliTests;
  const validMobilityTests = statistics.validMobilityTests;
  const bookingVsAvailableValue = statistics.bookingsVsAvailableTimes[0].booking_percentage;
  const deletedBookings = statistics.deletedBookings;
  const userIndexDistribution = statistics.userIndexDistribution;

  const ergometryPercent = regularUsers ? ((validErgometry / regularUsers) * 100).toFixed(2).toString() : '0';
  const fitnessTestPercent = regularUsers ? ((validFitnessTest / regularUsers) * 100).toFixed(2).toString() : '0';
  const oulunmalliPercent = regularUsers ? ((validOulunmalliTests / regularUsers) * 100).toFixed(2).toString() : '0';
  const mobilityPercent = regularUsers ? ((validMobilityTests / regularUsers) * 100).toFixed(2).toString() : '0';
  const booking_percentage = bookingVsAvailableValue ? Number(parseFloat(bookingVsAvailableValue).toFixed(2)).toString() : '0';

  const calculateAverageTimeDifference = (bookings: DeletedBooking[]): string => {
    const timeDifferences = bookings.map((booking: DeletedBooking) => {
      const deletionDate = new Date(booking.deletionDate);
      const startDate = new Date(booking.start);
      return (startDate.getTime() - deletionDate.getTime()) / (1000 * 60 * 60); // Convert milliseconds to hours
    });
  
    const averageTimeDifferenceInHours = timeDifferences.reduce((acc: number, curr: number) => acc + curr, 0) / timeDifferences.length;
  
    const days = Math.floor(averageTimeDifferenceInHours / 24);
    const remainingHours = averageTimeDifferenceInHours % 24;
    const hours = Math.floor(remainingHours);
    const minutes = Math.round((remainingHours - hours) * 60);
  
    let result = '';
    if (days > 0) {
      result += `${days} päivää`;
    }
    if (hours > 0) {
      if (result) result += ' ';
      result += `${hours} tunti${hours > 1 ? 'a' : ''}`;
    }
    if (minutes > 0) {
      if (result) result += ' ';
      result += `${minutes} minuuttia`;
    }
  
    return result || '0 minuuttia';
  };
  const countDeleteCauses = (deletedBookings: DeletedBooking[]): Record<string, number> => {
    const causeCounts: Record<string, number> = {};
  
    deletedBookings.forEach((booking) => {
      const cause = booking.delete_cause;
      if (cause in causeCounts) {
        causeCounts[cause] += 1;
      } else {
        causeCounts[cause] = 1;
      }
    });
  
    return causeCounts;
  };
  
  const deleteCauseCounts = countDeleteCauses(deletedBookings);
  const averageTimeDifference = deletedBookings.length > 0 ? calculateAverageTimeDifference(deletedBookings) : 'N/A';

  return (
    <Box>
      <Grid container spacing={2}>
        {/* Left Section */}
        <Grid item xs={12} md={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <UniversalStatsCard
                items={[{ title: "Kaikki alueesi testattavat", value: regularUsers.toString() }]}
              />
            </Grid>
            <Grid item xs={12}>
              <UniversalStatsCard
                items={[{ title: "Kaikki alueesi testaajat", value: statistics.userDistribution[1].toString() }]}
              />
            </Grid>
            <Grid item xs={12}>
              <UserDistribution data={statistics.userDistribution} />
            </Grid>
          </Grid>
        </Grid>

        {/* Middle Left Section */}
        <Grid item xs={12} md={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <UserIndexDistributionTable data={userIndexDistribution} />  {/* Render the PieChart component */}
            </Grid>
            <Grid item xs={12}>
              <UniversalStatsCard
                items={[
                  { title: "Voimassa olevat ergometriatestit", value: `${ergometryPercent}%` },
                  { title: "Voimassa olevat lihaskuntotestit", value: `${fitnessTestPercent}%` },
                  { title: "Voimassa olevat 'Oulun mallin' testit ", value: `${oulunmalliPercent}%` },
                  { title: "Voimassa olevat liikkuvuustestit", value: `${mobilityPercent}%` },
                ]}
              />
            </Grid>

          </Grid>
        </Grid>

        {/* Middle Right Section */}
        <Grid item xs={12} md={3}>
          <Grid container spacing={2}>
          <Grid item xs={12}>
              <UniversalStatsCard
                items={[{ title: "Tehdyt varaukset", value: statistics.totalBookings.toString() }]}
              />
            </Grid>
            <Grid item xs={12}>
              <TestExpiryAlerts alerts={statistics.expiryAlerts} />
            </Grid>
            <Grid item xs={12}>
              <BookingTrends labels={statistics.bookingTrends.labels} data={statistics.bookingTrends.data} />
            </Grid>
            <Grid item xs={12}>
              <TestExpiryTrends labels={statistics.expiryTrends.labels} data={statistics.expiryTrends.data} />
            </Grid>
          </Grid>
        </Grid>

        {/* Right Section */}
        <Grid item xs={12} md={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <UniversalStatsCard
                items={[
                  { title: 'Kokonaisvarausaste', value: `${booking_percentage}%` }
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <BookingLineGraph lineGraphData={statistics.lineGraphData} />
            </Grid>
            <Grid item xs={12}>
            <UniversalStatsCard
              items={[
                { title: 'Keskimääräinen aika testin perumiselle ennen testiä', value: `${averageTimeDifference}` },
                { title: 'Perumisen syyt:', value: '' },
                ...Object.entries(deleteCauseCounts).map(([cause, count]) => ({
                  title: cause,
                  value: count.toString(),
                }))
              ]}
              isDeleteCauseCard={true}
            />
          </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardTabOverview;
