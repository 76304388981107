import React from 'react';
import { Paper, Typography } from '@mui/material';
import { Pie } from 'react-chartjs-2';

interface UserDistributionProps {
  data: number[];
}

const UserDistribution: React.FC<UserDistributionProps> = ({ data }) => {
  const chartData = {
    labels: ['Testattava', 'Testaaja'],
    datasets: [
      {
        data: data,
        backgroundColor: ['#36A2EB', '#FF6384'],
        hoverBackgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
  };

  return (
    <Paper elevation={3} style={{ padding: '16px' }}>
      <Typography variant="h6" gutterBottom>
        Käyttäjäjakauma
      </Typography>
      <div style={{ height: '200px' }}>
        <Pie data={chartData} options={options} />
      </div>
    </Paper>
  );
};

export default UserDistribution;

