import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Box, Typography, List, ListItem, Paper, ListItemAvatar, ListItemText, Avatar, Card, CardContent, Snackbar, Alert } from '@mui/material';
import API from '../../API/API';
import { blue } from '@mui/material/colors';

interface Notification {
  id: number;
  message: string;
  date: string;
  posterId: number;
  firstname: string;
  lastname: string;
  important: number;
  area: string;
  fireStation: string;
  role: string;
}

const NotificationBoard: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const area = useSelector((state: RootState) => state.user.area); // Get the area from Redux
  const role = useSelector((state: RootState) => state.user.role); // Get the role from Redux
  const fireStation = useSelector((state: RootState) => state.user.fire_station);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  useEffect(() => {
      fetchNotifications(area, role, fireStation);
  }, [area, role, fireStation]);

  const fetchNotifications = async (area: string, role: string, fireStation: string) => {
    try {
      const response = await API.get('/getMessages', { params: { area, role, fireStation } });
      setNotifications(response.data); // Adjust based on your API response structure
    } catch (error) {
      setSnackbarMessage('Error fetching notifications');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Error fetching notifications', error);
    }
  };
  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{  }}>
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 1 }}>
      {notifications.length === 0 ? (
          <Typography variant="h6" align="center">
            Ei ilmoituksia
          </Typography>
        ) : (
        <List>
          
          {notifications.map(notification => (
            <ListItem key={notification.id} sx={{ marginBottom: 1, borderRadius: 1 }}>
              <Card sx={{ width: '100%', border: notification.important === 1 ? '1px solid blue' : 'none' }} elevation={3}>
                <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: blue[700] }}>
                      {notification.firstname[0]}{notification.lastname[0]}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                          sx={{ paddingRight: 2 }}
                          primary={notification.message}
                          secondary={
                            <>
                              {`Lähettäjä: ${notification.firstname} ${notification.lastname} ${new Date(notification.date).toLocaleString()} `}
                              {notification.important === 1 && (
                                <Typography component="span" sx={{ color: 'blue' }}>
                                  (Tärkeä)
                                </Typography>
                              )}
                              <br />
                              {`Alueet: ${notification.area}, Paloasemat: ${notification.fireStation}, Roolit: ${notification.role}`}
                            </>
                          }
                        />
                </CardContent>
              </Card>
            </ListItem>
          ))}
        </List>
        )}
      </Paper>
      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={4000} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%', padding: 3 }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
    
  );
};

export default NotificationBoard;
