import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import API from '../../API/API';
import { Box, Button, Typography, FormControl, InputLabel, Select, MenuItem, ListItemText } from '@mui/material';
import { useDispatch } from 'react-redux';
import { addBooking } from '../../../slices/bookingSlice';
import ConfirmDialog from '../../utils/DialogBox';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

interface BookingFormModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: () => void;
  selectedStartTime: string;
  selectedEndTimeStr: string;
  role: string | null;
  availableDates: {
    available_date_id: number;
    tester_firstname: string;
    tester_lastname: string;
    available_date: string;
    available_startTime: string;
    available_endTime: string;
    is_available: number;
    fire_station: string;
  }[];
  existingBookings: {
    start: string;
    end: string;
    fire_station: string;
  }[];
}

interface FireStation {
  id: number;
  fire_station: string;
}

interface User {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  area: string;
  fire_station: string;
}

interface Title {
  value: string;
  label: string;
  roles?: string[];
  duration: number;
}

const BookingFormModal: React.FC<BookingFormModalProps> = ({
  isOpen,
  onRequestClose,
  onSubmit,
  selectedStartTime,
  role,
  availableDates,
  existingBookings,
}) => {
  const [title, setTitle] = useState('');
  const [fireStationsWithBike, setFireStationsWithBike] = useState<FireStation[]>([]);
  const [allFireStations, setAllFireStations] = useState<FireStation[]>([]);
  const [fireStation, setFireStation] = useState('');
  const [filteredFireStations, setFilteredFireStations] = useState<FireStation[]>([]);
  const [titles, setTitles] = useState<Title[]>([]);
  const [availableTimes, setAvailableTimes] = useState<{ start: string; end: string; tester: string }[]>([]);
  const [selectedTime, setSelectedTime] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false); // State for confirm dialog
  const [users, setUsers] = useState<User[]>([]); // State for fetched users
  const [selectedUser, setSelectedUser] = useState<User | null>(null); // State for selected user
  const area = useSelector((state: RootState) => state.user.area);

  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch bike locations from db
    const fetchBikeLocations = async () => {
      try {
        const fireStationResponse = await API.get('/bike-location');
        setFireStationsWithBike(fireStationResponse.data.location);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };
    fetchBikeLocations();

    // Fetch titles, labels and durations from db
    const fetchTitles = async () => {
      try {
        const titlesResponse = await API.get('/getTitles');
        setTitles(titlesResponse.data);
      } catch (error) {
        console.error('Error', error);
      }
    };
    fetchTitles();

    const specificDate = selectedStartTime.split('T')[0];
    const availableDatesForSelectedDate = availableDates.filter(date => date.available_date === specificDate && date.is_available);
    const availableFireStationsForSelectedDate = availableDatesForSelectedDate.map(date => ({
      fire_station: date.fire_station,
      id: date.available_date_id
    }));

    setAllFireStations(availableFireStationsForSelectedDate);

  }, [role, selectedStartTime, availableDates]);

  useEffect(() => {
    // Fetch users based on the role
    const fetchUsers = async () => {
      try {
        let userResponse;
        if (role === 'admin') {
          userResponse = await API.get('/getAllRegularUsers');
        } else if (role === 'Esihenkilö' || role === 'Testaaja') {
          userResponse = await API.get('/getRegularUsersFromArea', {
            params: { area: area }
          });
        }
        if (userResponse && userResponse.data) {
          setUsers(userResponse.data);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
  
    fetchUsers();
  }, [role]);

  useEffect(() => {
    let availableFireStations: FireStation[] = [];

    if (title === 'Polkupyöräergometri') {
      availableFireStations = fireStationsWithBike.filter(station =>
        availableDates.some(date => date.fire_station === station.fire_station && date.is_available)
      );
    } else if (title === 'Lihaskuntotesti' || title === 'Lihaskuntotesti + liikkuvuus' || title === 'Savusukellusta simuloiva rata') {
      availableFireStations = availableDates
        .filter(date => date.is_available && date.tester_firstname && date.tester_lastname)
        .map(date => ({
          fire_station: date.fire_station,
          id: date.available_date_id
        }));
    } else {
      availableFireStations = allFireStations.filter(station =>
        availableDates.some(date => date.fire_station === station.fire_station && date.is_available)
      );
    }

    // Deduplicate fire stations
    const uniqueFireStations = Array.from(new Set(availableFireStations.map(station => station.fire_station)))
      .map(fire_station => {
        return availableFireStations.find(station => station.fire_station === fire_station) as FireStation;
      })
      .filter(station => station !== undefined); // Ensure no undefined values

    setFilteredFireStations(uniqueFireStations);
  }, [title, fireStationsWithBike, allFireStations, availableDates]);

  useEffect(() => {
    if (fireStation) {
      const selectedTitle = titles.find(t => t.value === title);
      const interval = selectedTitle ? selectedTitle.duration * 60 * 1000 : 60 * 60 * 1000; // Default to 60 min if not found

      const availableTimesForStation = availableDates
        .filter(date => date.is_available && date.fire_station === fireStation)
        .flatMap(date => {
          const startTime = new Date(`${date.available_date}T${date.available_startTime}`);
          const endTime = new Date(`${date.available_date}T${date.available_endTime}`);
          const times = [];
          let currentTime = new Date(startTime);

          while (currentTime < endTime) {
            let nextTime = new Date(currentTime.getTime() + interval);

            // Skip the 11:00-12:00 slot for both titles
            if ((currentTime.getHours() === 11 && nextTime.getHours() === 12) ||
                (currentTime.getHours() === 10 && currentTime.getMinutes() === 45 && nextTime.getHours() === 11 && nextTime.getMinutes() === 45) ||
                (currentTime.getHours() === 10 && currentTime.getMinutes() === 45 && nextTime.getHours() === 11 && nextTime.getMinutes() === 30) ||
                (currentTime.getHours() === 11 && currentTime.getMinutes() === 0 && nextTime.getHours() === 11 && nextTime.getMinutes() === 45) ||
                (currentTime.getHours() === 15 && currentTime.getMinutes() === 45 && nextTime.getHours() === 16 && nextTime.getMinutes() === 30)
              ) {
              currentTime.setTime(nextTime.getTime());
              continue;
            }

            // Find the next available time slot that is not overlapping with existing bookings
            const overlappingBooking = existingBookings.find(booking => {
              const bookingStart = new Date(booking.start);
              const bookingEnd = new Date(booking.end);
              return (bookingStart < nextTime && bookingEnd > currentTime);
            });

            if (!overlappingBooking) {
              times.push({
                start: currentTime.toISOString(),
                end: nextTime.toISOString(),
                tester: `${date.tester_firstname} ${date.tester_lastname}`
              });
              currentTime.setTime(nextTime.getTime());
            } else {
              currentTime.setTime(new Date(overlappingBooking.end).getTime());
            }
          }
          return times;
        });
      setAvailableTimes(availableTimesForStation);
    }
  }, [fireStation, availableDates, existingBookings, title, titles]);
  const handleConfirm = async () => {

    const startDate = new Date(selectedTime);
    const selectedTitle = titles.find(t => t.value === title);
    const interval = selectedTitle ? selectedTitle.duration * 60 * 1000 : 60 * 60 * 1000; // Default to 60 min if not found
    const endDate = new Date(startDate.getTime() + interval);
    const selectedSlot = availableTimes.find(time => time.start === selectedTime);
    const testerName = selectedSlot ? selectedSlot.tester : '';

    const bookingData = {
      start: startDate,
      end: endDate,
      title: title,
      fire_station: fireStation,
      tester: testerName,
      approved: 0,
      email: selectedUser?.email || undefined,
    };

    try {
      const response = await API.post('/varaukset', bookingData);
      dispatch(addBooking(response.data.booking));
    } catch (error) {
      console.error('Error creating booking:', error);
    }

    onSubmit();
    resetForm();
    onRequestClose();
    setConfirmDialogOpen(false);
  };

  const handleSubmit = () => {
    setConfirmDialogOpen(true);
  };

  const handleCancel = () => {
    resetForm();
    onRequestClose();
  };

  const resetForm = () => {
    setTitle('');
    setFireStation('');
    setSelectedTime('');
    setFirstname('');
    setLastname('');
    setPhonenumber('');
    setSelectedUser(null); // Reset selected user
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={handleCancel}
        contentLabel="Booking Form"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <Typography variant="h6">{'Uusi ajanvaraus'}</Typography>
        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Testi</InputLabel>
            <Select 
              value={title} 
              onChange={(e) => setTitle(e.target.value)} 
              required
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                PaperProps: {
                  style: {
                    maxHeight: 200 // Adjust max height to make it scrollable
                  }
                }
              }}
            >
              {titles.map(({ value, label, roles }) => (
                (!roles || roles.includes(role || '')) && (
                  <MenuItem key={value} value={value} disabled={value === ''}>{label}</MenuItem>
                )
              ))}
            </Select>
          </FormControl>

          {(title === 'Polkupyöräergometri' || title === 'Lihaskuntotesti' || title === 'Lihaskuntotesti + liikkuvuus' || title === 'Savusukellusta simuloiva rata') && (
            filteredFireStations.length === 0 ? (
              <>
                <Typography 
                  variant="body1" 
                  color="textSecondary" 
                  margin="normal" 
                  component="div" 
                  sx={{
                    border: '1px solid red',
                    borderRadius: '5px',
                    backgroundColor: '#ffe5e5', // light red background
                    padding: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Ikävä kyllä et voi varata testausaikaa {title} juuri nyt. Tämä johtuu siitä, että paloasemalle, jolla on tarvittava välineistö, ei ole suunniteltuja testausaikoja.
                  <br />
                  <br />
                  Jos ajanvarauksesi on kiireellinen ja edelliset testisi ovat menossa umpeen, ota yhteyttä alueesi esihenkilöön.
                </Typography>
              </>
            ) : (
              <>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Paloasema</InputLabel>
                  <Select 
                    value={fireStation} 
                    onChange={(e) => setFireStation(e.target.value)} 
                    required
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      PaperProps: {
                        style: {
                          maxHeight: 200 // Adjust max height to make it scrollable
                        }
                      }
                    }}
                  >
                    <MenuItem value="" disabled>Valitse paloasema</MenuItem>
                    {filteredFireStations.map((station) => (
                      <MenuItem key={station.id} value={station.fire_station}>{station.fire_station}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {fireStation && availableTimes.length > 0 && (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Varaa aika</InputLabel>
                    <Select 
                      value={selectedTime} 
                      onChange={(e) => setSelectedTime(e.target.value)} 
                      required
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        PaperProps: {
                          style: {
                            maxHeight: 200 // Adjust max height to make it scrollable
                          }
                        }
                      }}
                    >
                      <MenuItem value="" disabled>Valitse aika</MenuItem>
                      {availableTimes.map((time, index) => (
                        <MenuItem key={index} value={time.start}>
                          {`${new Date(time.start).toLocaleDateString()} ${new Date(time.start).toLocaleTimeString()} - ${new Date(time.end).toLocaleTimeString()} - ${time.tester}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {(role === 'Testaaja' || role === 'Esihenkilö' || role === 'admin') && (
                  <>
                  <Typography 
                  variant="body1" 
                  color="textSecondary" 
                  margin="normal" 
                  component="div" 
                  sx={{
                    border: '1px solid red',
                    borderRadius: '5px',
                    backgroundColor: '#ffe5e5', // light red background
                    padding: '10px',
                    lineHeight: '1.5',
                  }}
                >
                  Jos varaat aikaa toiselle henkilölle, valitse henkilö alla olevasta listasta. Muuten jätä se tyhjäksi.
                </Typography>

                    {/* Select element to choose a user */}
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Valitse käyttäjä</InputLabel>
                      <Select
                        value={selectedUser?.id || ''}
                        onChange={(e) => {
                          const userId = Number(e.target.value);
                          const user = users.find(user => user.id === userId) || null;
                          setSelectedUser(user);
                          if (user) {
                            setFirstname(user.firstname);
                            setLastname(user.lastname);
                          }
                        }}
                        displayEmpty
                        renderValue={(selected) => {
                          const user = users.find(u => u.id === selected);
                          return user ? `${user.firstname} ${user.lastname}` : ''; // Return empty string when no user is selected
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          PaperProps: {
                            style: {
                              maxHeight: 200 // Adjust max height to make it scrollable
                            }
                          }
                        }}
                      >
                        {users.map(user => (
                          <MenuItem key={user.id} value={user.id}>
                            <ListItemText
                              primary={`${user.firstname} ${user.lastname} - ${user.email}`}
                              secondary={`Asemaryhmä: ${user.area}, Paloasema: ${user.fire_station}`}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
              </>
            )
          )}
          <Box mt={2} display="flex" justifyContent="space-between">
            <Box>
              {title && filteredFireStations.length > 0 && fireStation && selectedTime && (
                <Button type="submit" variant="contained" color="success">Varaa</Button>
              )}
            </Box>
            <Button type="button" color="error" onClick={handleCancel}>Peruuta</Button>
          </Box>
        </form>
      </Modal>
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={handleConfirm}
        title="Vahvista ajanvaraus"
        contentText="Toimintakykytestejä ei saa suorittaa sairaana. Vahvistamalla tämän varauksen, ilmoitat, että olet toimintakyvyltäsi kykenevä suorittamaan testin. 
        Saat varauksesta vielä vahvistussähköpostin, jonka mukana toimitetaan myös tarkat valmistautumisohjeet testihin."
        cancelText="Peruuta"
        confirmText="Vahvista"
        confirmButtonColor="success"
      />
    </>
  );
};

export default BookingFormModal;
