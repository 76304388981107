// src/utils/statusUtils.ts

export const getStatus = (approved?: number): string => {
    switch (approved) {
      case 1:
        return 'Hyväksytty';
      case 2:
        return 'Hylätty';
      default:
        return 'Ei suoritusta';
    }
  };
  
  export const getStatusStyles = (approved?: number): { backgroundColor: string } => {
    switch (approved) {
      case 1:
        return { backgroundColor: 'green' };
      case 2:
        return { backgroundColor: 'red' };
      default:
        return { backgroundColor: 'blue' };
    }
  };
  