import React from 'react';
import { Paper, Typography, Box } from '@mui/material';

interface UniversalStatsCardProps {
  items: { title: string; value: string }[];
  isDeleteCauseCard?: boolean;  // Prop to determine the rendering style
}

const UniversalStatsCard: React.FC<UniversalStatsCardProps> = ({ items, isDeleteCauseCard = false }) => {
  return (
    <Paper elevation={3} style={{ padding: '16px' }}>
      {items.map((item, index) => (
        <div key={index} style={{ marginBottom: isDeleteCauseCard ? '8px' : '16px' }}>
          {isDeleteCauseCard ? (
            // Check if the title matches the specific one for special vertical rendering
            item.title === 'Keskimääräinen aika testin perumiselle ennen testiä' ? (
              <>
                <Typography variant="h6" gutterBottom>
                  {item.title}
                </Typography>
                <Typography variant="h5">
                  {item.value}
                </Typography>
                <br />
              </>
            ) : (
              // Render title and value horizontally for other delete causes
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body1">
                  {item.title}
                </Typography>
                <Typography variant="h5">
                  {item.value}
                </Typography>
              </Box>
            )
          ) : (
            // Default rendering for non-delete cause cards (title above value)
            <>
              <Typography variant="h6" gutterBottom>
                {item.title}
              </Typography>
              <Typography variant="h5">
                {item.value}
              </Typography>
              <br />
            </>
          )}
        </div>
      ))}
    </Paper>
  );
};

export default UniversalStatsCard;
