import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import API from '../API/API';
import Dashboard from '../Dashboard/Dashboard';
import Homepage from '../Layout/Homepage';
import { setUser } from '../../slices/userSlice';
import Loading from '../Loading/Loading';

const AuthChecker: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refreshAttempted, setRefreshAttempted] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = useSelector((state: RootState) => state.user.role);

  const checkAuth = async () => {
    let token = localStorage.getItem('accessToken');
    if (!token && !refreshAttempted) {
      setRefreshAttempted(true);
      try {
        const response = await API.post('/refresh');

        if (response.status === 403 || response.status === 401) {
          setAuthenticated(false);
          setLoading(false);
          navigate('/login');
          return;
        }
        const { accessToken } = response.data;
        localStorage.setItem('accessToken', accessToken);
        API.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        token = accessToken; // Update token
        setRefreshAttempted(false); // Reset refresh attempt status
      } catch (error) {
        console.error('Error during token refresh:', error);
        setAuthenticated(false);
        setLoading(false);
        navigate('/login');
        return;
      }
    }

    if (token) {
      try {
        const response = await API.get('/varaukset');
        if (response.status === 200) {
          setAuthenticated(true);
          if (!role) {
            const userResponse = await API.get('/currentUser'); 
            dispatch(setUser(userResponse.data));
          }
        } else {
          setAuthenticated(false);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error checking auth:', error);
        setAuthenticated(false);
        navigate('/login');
      }
    } else {
      setAuthenticated(false);
      navigate('/login');
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  useEffect(() => {
    checkAuth();
  }, []);

  if (loading) {
    return <Loading />
  }

  if (!authenticated) {
    return <Navigate to="/login" />;
  }

  if (role === 'admin' || role === 'Esihenkilö') {
    return <Dashboard />;
  }

  return <Homepage />;
};

export default AuthChecker;
