import React, { useEffect, useState } from 'react';
import API from '../API/API';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import {
  Box,
  Tabs,
  Tab,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import Layout from '../Layout/Layout';
import DashboardTabUsers from './Users/DashboardTabUsers';
import DashboardTabBookings from './Bookings/DashboardTabBookings';
import DashboardTabPlanning from './Planning/DashboardTabPlanning';
import DashboardTabOverview from './Statistics/DashboardTabOverview';
import NotificationBoard from './NotificationBoard/NotificationBoard';
import BikeLocations from './BikeLocations/BikeLocations';
import { useScreenSize } from '../ScreenSizeContext'; // Import the useScreenSize hook
import { useDispatch } from 'react-redux';
import { setBookings } from '../../slices/bookingSlice';

interface User {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  role: string;
  area: string;
  phonenumber: string;
  ergometry_expirary_date: string;
  fitness_test_expirary_date: string;
  oulun_malli_expirary_date: string;
  mobility_test_expirary_date: string;
  ergometry_index: string;
  fitness_test_index: string;
  goal: string;
  fire_station: string;
  userIndex: string;
}

const Dashboard: React.FC = () => {
  const { isMobile } = useScreenSize(); // Get screen size info
  const dispatch = useDispatch();
  const [users, setUsers] = useState<User[]>([]);
  const [testers, setTesters] = useState<User[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const role = useSelector((state: RootState) => state.user.role);
  const area = useSelector((state: RootState) => state.user.area);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (role === 'admin') {
          // Fetch all users and testers if the role is admin
          const testerResponse = await API.get('/getAllTesters');
          const userResponse = await API.get('/getAllUsers');
  
          setTesters(testerResponse.data);
          setUsers(userResponse.data);
        } else if (role === 'Esihenkilö') {
          // Fetch users and testers filtered by area if the role is Esihenkilö
          const testerResponse = await API.get('/getTestersFromArea', {
            params: { area }
          });
          const userResponse = await API.get('/getRegularUsersFromArea', {
            params: { area }
          });
  
          setTesters(testerResponse.data);
          setUsers(userResponse.data);
        }
  
        // Fetch bookings for all roles
        const bookingsResponse = await API.get('/varaukset');
        dispatch(setBookings(bookingsResponse.data.bookings));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [role, area, dispatch]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleDropdownChange = (event: SelectChangeEvent<number>) => {
    setSelectedTab(Number(event.target.value));
  };

  const renderTabs = () => (
    <Tabs
      value={selectedTab}
      onChange={handleTabChange}
      orientation={isMobile ? 'vertical' : 'horizontal'}
      variant={isMobile ? 'scrollable' : 'standard'}
      style={{ marginBottom: '16px' }}
    >
      <Tab label="Statistiikka" />
      <Tab label="Henkilöstö" />
      <Tab label="Varaukset" />
      <Tab label="Suunnittelu" />
      <Tab label="Pyörien sijainnit" />
      <Tab label='Ilmoitustaulu' />
    </Tabs>
  );

  const renderDropdown = () => (
    <FormControl fullWidth sx={{ my: 3}}>
      <InputLabel id="tab-dropdown-label">Valitse näkymä</InputLabel>
      <Select
        labelId="tab-dropdown-label"
        id="tab-dropdown"
        value={selectedTab}
        onChange={handleDropdownChange}
      >
        <MenuItem value={0}>Statistiikka</MenuItem>
        <MenuItem value={1}>Henkilöstö</MenuItem>
        <MenuItem value={2}>Varaukset</MenuItem>
        <MenuItem value={3}>Suunnittelu</MenuItem>
        <MenuItem value={4}>Pyörien sijainnit</MenuItem>
        <MenuItem value={5}>Ilmoitustaulu</MenuItem>
      </Select>
    </FormControl>
  );

  return (
    <Layout>
      <Box p={isMobile ? 1 : 3}>
{/*       {!isMobile && (
          <Typography variant="h4" gutterBottom>
            Hallintapaneeli
          </Typography>
        )} */}
        {isMobile ? renderDropdown() : renderTabs()}
        {selectedTab === 0 && (
          <DashboardTabOverview role={role} area={area} />
        )}
        {selectedTab === 1 && (
          <DashboardTabUsers testers={testers} users={users} role={role} />
        )}
        {selectedTab === 2 && (
          <DashboardTabBookings role={role} />
        )}
        {selectedTab === 3 && (
          <DashboardTabPlanning role={role} />
        )}
        {selectedTab === 4 && (
          <BikeLocations />
        )}
        {selectedTab === 5 && (
          <NotificationBoard />
        )}
      </Box>
    </Layout>
  );
};

export default Dashboard;
