import axios from 'axios';

// Deployment
const url = 'https://firefit.kspela.fi/api'
const refresh_url = 'https://firefit.kspela.fi/api/refresh'

// Development
/* const url = 'http://localhost:4000/api'
const refresh_url = 'http://localhost:4000/api/refresh' */

// Create an Axios instance
const API = axios.create({
  baseURL: url, // Deployment
  withCredentials: true, // Ensure cookies are sent with requests
});

// Function to get CSRF token from server
const fetchCsrfToken = async () => {
  try {
    const response = await API.get('/csrf-token');
    const csrfToken = response.data.csrfToken;
    document.cookie = `XSRF-TOKEN=${csrfToken}; path=/; secure; samesite=strict`;
    return csrfToken;
  } catch (error) {
    console.error('Failed to fetch CSRF token', error);
    throw error;
  }
};

// Fetch CSRF token on initial load
fetchCsrfToken();

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    // Get the CSRF token from cookies
    const getCsrfToken = () => {
      const name = 'XSRF-TOKEN=';
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookies = decodedCookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        if (cookie.indexOf(name) === 0) {
          return cookie.substring(name.length, cookie.length);
        }
      }
      return '';
    };

    const csrfToken = getCsrfToken();
    if (csrfToken) {
      config.headers['X-XSRF-TOKEN'] = csrfToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Skip token refresh for /login route
    if (originalRequest.url.includes('/login')) {
      return Promise.reject(error);
    }

    if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        }).then(token => {
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          return axios(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(async (resolve, reject) => {
        try {
          const response = await axios.post(refresh_url, null, { withCredentials: true }); // Deployment
          if (response.status === 403 || response.status === 401) {
            processQueue(response, null);
            localStorage.removeItem('accessToken');
            window.location.href = '/login'; // Adjust this to your login route
            return reject(response);
          }

          const { accessToken } = response.data;
          localStorage.setItem('accessToken', accessToken);
          API.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
          originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
          processQueue(null, accessToken);
          resolve(API(originalRequest));
        } catch (err) {
          processQueue(err, null);
          localStorage.removeItem('accessToken');
          window.location.href = '/login'; // Adjust this to your login route
          reject(err);
        } finally {
          isRefreshing = false;
        }
      });
    }

    return Promise.reject(error);
  }
);

export default API;
