import React from 'react';
import Modal from 'react-modal';
import Register from './Register';

interface RegisterModalProps {
  isOpen: boolean;
  onClose: () => void;
  userRole: string;
}

const RegisterModal: React.FC<RegisterModalProps> = ({ isOpen, onClose, userRole }) => {
  const customStyles = {
    content: {
      width: '90%', // Increase the width as needed
      maxWidth: '800px', // Ensure it doesn't go beyond a maximum width
      margin: 'auto', // Center the modal horizontally
      marginTop: '50px',
      padding: '10px', // Add padding for better spacing
      borderRadius: '10px', // Optional: Add rounded corners
      overflow: 'auto', // Ensure content is scrollable if it overflows
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Add a background overlay color
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Register"
      className="register-modal-content"
      overlayClassName="modal-overlay"
      style={customStyles}
    >
      <Register onClose={onClose} userRole={userRole} />
    </Modal>
  );
};

export default RegisterModal;
