import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Select, MenuItem, FormControl, InputLabel, TextField } from '@mui/material';

interface ConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  contentText: string;
  cancelText: string;
  confirmText: string;
  confirmButtonColor?: 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  confirmButtonVariant?: 'text' | 'outlined' | 'contained';
  cancelButtonColor?: 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  showCauseField?: boolean;
  cause?: string;
  setCause?: (cause: string) => void;
  availableDeleteCauses?: Array<{ id: number; cause: string }>;
  showTextField?: boolean;
  textFieldValue?: string;
  setTextFieldValue?: (value: string) => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  contentText,
  cancelText,
  confirmText,
  cancelButtonColor = 'error',
  confirmButtonColor = 'error',
  confirmButtonVariant = 'contained',
  showCauseField = false,
  cause = '',
  setCause = () => {},
  availableDeleteCauses = [],
  showTextField = false,
  textFieldValue = '',
  setTextFieldValue = () => {},
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
      {contentText.split('\n').map((text, index) => (
          <DialogContentText key={index} variant="body2" paragraph>
            {text}
          </DialogContentText>
        ))}
        {showCauseField && (
          <FormControl fullWidth margin="dense">
            <InputLabel id="delete-cause-label">Poistamisen syy</InputLabel>
            <Select
              labelId="delete-cause-label"
              value={cause}
              onChange={(e) => setCause(e.target.value)}
              label="Poistamisen syy"
            >
              {availableDeleteCauses.map(({ id, cause }) => (
                <MenuItem key={id} value={cause}>
                  {cause}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {showTextField && (
          <TextField
            fullWidth
            margin="dense"
            label="Vapaamuotoinen selvitys..."
            value={textFieldValue}
            onChange={(e) => setTextFieldValue(e.target.value)}
            multiline
            rows={3}
            variant="outlined"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={cancelButtonColor}>
          {cancelText}
        </Button>
        <Button onClick={onConfirm} color={confirmButtonColor} variant={confirmButtonVariant}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
