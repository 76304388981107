import React, { useState, useEffect } from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, Box, Typography, SelectChangeEvent, Paper, Checkbox, FormControlLabel } from '@mui/material';
import API from '../../API/API';
import Scheduler from './SchedulerTable';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useScreenSize } from '../../ScreenSizeContext'; // Import the useScreenSize hook

interface User {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  role: string;
  area: string;
  phonenumber: string;
}

interface DashboardTabPlanningProps {
  role: string;
}

const DashboardTabPlanning: React.FC<DashboardTabPlanningProps> = ({ role }) => {
  const [allTesters, setAllTesters] = useState<User[]>([]);
  const [selectedTester, setSelectedTester] = useState<User | null>(null);
  const [scheduleForSelf, setScheduleForSelf] = useState(false); 
  const area = useSelector((state: RootState) => state.user.area);
  const user = useSelector((state: RootState) => state.user); 
  const { isMobile } = useScreenSize(); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (role === 'admin') {
          const allTestersResponse = await API.get('/getAllTesters');
          setAllTesters(allTestersResponse.data);
        } else if (role === 'Esihenkilö') {
          const testersInAreaResponse = await API.get('/getTestersFromArea', {
            params: { area }
          });
          setAllTesters(testersInAreaResponse.data);
        }
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };
    fetchData();
  }, [role]);

  const handleTesterChange = (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value;
    const tester = allTesters.find(t => t.id === selectedId) || null;
    setSelectedTester(tester);
  };

  const handleScheduleForSelfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScheduleForSelf(event.target.checked);
    if (event.target.checked) {
      setSelectedTester(user); // If checked, select the current logged-in user
    } else {
      setSelectedTester(null); // Reset when unchecked
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%">
      <Paper 
        elevation={3} 
        sx={{ 
          padding: '16px', 
          width: isMobile ? '100%' : '80%', 
          maxHeight: '100%' 
        }}
      >
        <Grid container spacing={3} sx={{ marginBottom: '16px' }}>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={scheduleForSelf} onChange={handleScheduleForSelfChange} />}
              label="Suunnittelen testiaikoja itselleni" // Label to allow scheduling for themselves
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth disabled={scheduleForSelf}>
              <InputLabel id="tester-select-label">Valitse testaaja</InputLabel>
              <Select
                labelId="tester-select-label"
                value={selectedTester?.id || ''}
                onChange={handleTesterChange}
                disabled={scheduleForSelf} 
              >
                {allTesters.map(tester => (
                  <MenuItem key={tester.id} value={tester.id}>
                    {tester.firstname} {tester.lastname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {selectedTester && (
              <Box>
                <Scheduler 
                  testerId={selectedTester.id} 
                  firstname={selectedTester.firstname} 
                  lastname={selectedTester.lastname} 
                  area={selectedTester.area} 
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default DashboardTabPlanning;
