import React, { useState, useEffect } from 'react';

const CookieConsentBanner: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookiesAccepted = document.cookie.split('; ').find(row => row.startsWith('cookies_accepted='));
    if (!cookiesAccepted) {
      setIsVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    document.cookie = "cookies_accepted=true; path=/; max-age=" + 60 * 60 * 24 * 365;
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div style={bannerStyle}>
      <div style={contentStyle}>
        <div>
          <p style={textStyle}>
            Tämä sivusto käyttää evästeitä sekä tallentaa ja käyttää tietosuojaselosteessa mainittuja käyttäjätietoja. Jatkamalla hyväksyt ehdot.
          </p>
          <p style={textStyle}>
            Evästeitä käytetään ja tallennetaan ainoastaan sivuston välttämättömän toiminnan takaamiseksi.
          </p>
          <p style={textStyle}>
            Alla olevan linkin takaa voit tutustua tämän sovelluksen eväste- ja tietosuojaselosteeseen.
          </p>
        </div>
        <div style={buttonContainerStyle}>
          <a href="/tietosuoja-ja-evasteseloste" style={linkStyle}>Eväste- ja tietosuojaseloste</a>
          <button onClick={acceptCookies} style={buttonStyle}>Hyväksy</button>
        </div>
      </div>
    </div>
  );
};

const bannerStyle: React.CSSProperties = {
  position: 'fixed',
  bottom: 0,
  width: '100%',
  background: '#333',
  color: 'white',
  padding: '10px',
  zIndex: 1000,
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const contentStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '100%',
  width: '100%',
  padding: '10px 20px',
  boxSizing: 'border-box',
  gap: '10px',
};

const buttonContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  justifyContent: 'center',
  alignItems: 'center',
};

const textStyle: React.CSSProperties = {
  color: 'white',
  fontSize: '16px',
  textAlign: 'center',
  maxWidth: '100%',
  margin: '0 10px',
};

const linkStyle: React.CSSProperties = {
  color: '#4CAF50',
  margin: '5px',
  textDecoration: 'underline',
};

const buttonStyle: React.CSSProperties = {
  background: '#4CAF50',
  color: '#fff',
  border: 'none',
  padding: '10px 20px',
  cursor: 'pointer',
  fontSize: '16px',
};

export default CookieConsentBanner;
