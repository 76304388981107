import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import Logo from '../../img/K-SPelastuslaitos-logo.svg';

const Loading: React.FC = () => {
  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="center" 
      height="100vh"
      textAlign="center"
      sx={{ gap: 2 }}
    >
      <img src={Logo} alt="Loading Logo" style={{ width: 100, height: 'auto' }} />
      <CircularProgress />
      <Typography variant="h6">
        Ladataan sisältöä, odota hetki...
      </Typography>
    </Box>
  );
};

export default Loading;

