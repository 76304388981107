import React from 'react';
import { Paper, Typography } from '@mui/material';
import { Pie } from 'react-chartjs-2';

interface UserIndexDistributionTableProps {
  data: Record<string, number>;
}

const UserIndexDistributionTable: React.FC<UserIndexDistributionTableProps> = ({ data }) => {
  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        data: Object.values(data),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'], // Add more colors if needed
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
  };

  return (
    <Paper elevation={3} style={{ padding: '16px' }}>
    <Typography variant="h6" gutterBottom>
      Kelpoisuusluokkajako
    </Typography>
    <div style={{ height: '200px' }}>
      <Pie data={chartData} options={options} />
    </div>
  </Paper>
  );
};

export default UserIndexDistributionTable;
