import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import Homepage from './components/Layout/Homepage';
import Login from './components/Login/Login';
import AuthChecker from './components/middleware/AuthChecker';
import Dashboard from './components/Dashboard/Dashboard';
import './App.css';
import { ScreenSizeProvider } from './components/ScreenSizeContext'; // Ensure correct import path
import CookieConsentBanner from './components/CookieConsentBanner';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ScreenSizeProvider>
          <Router>
            
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/"
                element={
                  <AuthChecker>
                    {/* <CookieConsentBanner /> */}
                    <Homepage />
                  </AuthChecker>
                }
              />
              <Route path="/dashboard" element={
                <AuthChecker>
                  {/* <CookieConsentBanner /> */}
                  <Dashboard />
                </AuthChecker>
              } />
            </Routes>
          </Router>
        </ScreenSizeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
